import { useQuery } from '@tanstack/react-query'
import { ExtractFnReturnType, QueryConfig } from '@/api/react-query';
import axios from 'axios'
import apiUrl from '@/config'

export const getRecruiter = async ({ recruiterId }: { recruiterId?: string }): Promise<any | undefined> => {
    return await axios.get(`${apiUrl}/recruiters/${recruiterId}/details`)
    .catch(error => {
        return { error }
    })
}

type QueryFnType = typeof getRecruiter

type UseRecruiterOptions = {
    recruiterId?: string | undefined,
    config?: QueryConfig<QueryFnType>
}

export const useRecruiter = ({ recruiterId, config }: UseRecruiterOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['recruiter', recruiterId],
        queryFn: () => getRecruiter({ recruiterId }),
        onError: (error) => {
            console.log(error)
            throw error
        },
        enabled: !!recruiterId,
        ...config,
    })
}