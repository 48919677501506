import { useMutation } from "@tanstack/react-query"
import { MutationConfig } from '@/api/react-query';
import { toast } from "react-toastify"
import axios from 'axios';
import apiUrl from '@/config'

type ApplyByATSProps = {
    uuid: string | null;
    vacancy_id: string | null;
}

export const applyByATS = ({ uuid, vacancy_id }: ApplyByATSProps) => {
    return axios.patch(`${apiUrl}/vacancies/${vacancy_id}/candidates/${uuid}/apply_by_ats`
    ).catch((error) => {
        return { error }
    })
}

type UseApplyByATSOptions = {
    config?: MutationConfig<typeof applyByATS>;
}

export const useApplyByATS = ({ config }: UseApplyByATSOptions) => {
    const mutation = useMutation({
        onError: (error) => {
            if (error instanceof Error) {
                console.log(error)
                toast.error(error.message)
            } else {
                console.log(error)
                toast.error("An unexpected error occurred")
            }
        },
        ...config,
        mutationFn: applyByATS
    })

    return { ...mutation, isLoading: mutation.isLoading }
}