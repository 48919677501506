import { useMutation } from "@tanstack/react-query"
import { MutationConfig } from '@/api/react-query';
import { toast } from 'react-toastify'
import axios from 'axios';
import apiUrl from '@/config'

type Props = {
    interested_salary: boolean;
    uuid: string;
}

export const submitData = ({ interested_salary, uuid }: Props) => {
    return axios.patch(`${apiUrl}/candidates/${uuid}`, {
        candidate_application: {
            interested_salary
        }
    }).catch((error) => {
        return { error }
    })
}

type UseSubmitDataOptions = {
    config?: MutationConfig<typeof submitData>;
}

export const useSubmitData = ({ config }: UseSubmitDataOptions) => {
    const mutation = useMutation({
        onError: (error) => {
            if (error instanceof Error) {
                console.log(error)
                toast.error(error.message)
            } else {
                console.log(error)
                toast.error("An unexpected error occurred")
            }
        },
        ...config,
        mutationFn: submitData
    })

    return { ...mutation, isLoading: mutation.isLoading }
}