import { Helmet } from "react-helmet-async";

type HeadProps = {
  title?: string;
  description?: string;
};

export const Head = ({ title, description }: HeadProps = {}) => {
  return (
    <Helmet
      title={title ? `${title} | Cielo TaaS` : undefined}
      defaultTitle="Cielo TaaS"
    >
      <meta name="description" content={description} />
      <meta
        name="theme-color"
        content="#804FEE"
        media="(prefers-color-scheme: light)"
      />
      <meta
        name="theme-color"
        content="#0a0d1c"
        media="(prefers-color-scheme: dark)"
      />
    </Helmet>
  );
};
