import { ActionButtons } from "@/components/Elements/ActionButtons";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { ErrorPage } from "@/components/Layout/Error";
import { generateCompensation } from "@/utils/generateCompensation";
import { Head } from "@/components/Head";
import { Hero } from "@/components/Layout/Hero";
import { MainLayout } from "@/components/Layout/MainLayout";
import { MatchedSkills } from "./components/MatchedSkills";
import { Popup } from "@/components/Elements/Popup";
import { Spinner } from "@/components/Elements/Spinner";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";
import { useTheme } from "@/contexts";
import { useEffect } from "react";
import { useJobDescription } from "./hooks/useJobDescription";

export const JobDescription = () => {
  const { isDarkMode } = useTheme();

  const {
    data,
    companyData,
    body,
    isLoading,
    accepted,
    setAccepted,
    handleResponse,
    applicationError,
    vacancySkills,
  } = useJobDescription();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (applicationError?.message) {
    const errorMessage = applicationError.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={applicationError.message} />;
  }

  const generateSubtitle = () => {
    let type;
    const arrangement =
      data?.arrangement?.charAt(0).toUpperCase() + data?.arrangement?.slice(1);

    if (data && data.location && data.vacancy_type) {
      if (data?.vacancy_type === "zero_hours") {
        type = "Zero hours";
      } else if (data?.vacancy_type === "part_time") {
        type = "Part-time";
      } else if (data?.vaacncy_type === "temp") {
        type = "Temporary";
      } else {
        type = data?.vacancy_type;
      }
      return `${data?.location} • ${type?.charAt(0).toUpperCase()}${type?.slice(
        1
      )}${data?.arrangement ? arrangement && ` • ${arrangement}` : ""}`;
    }
  };

  if (data && companyData) {
    return (
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
      >
        <MainLayout currentStep={1}>
          <Head title={data.title} />
          <div className="flex flex-col">
            <Hero
              showLogo
              logo={companyData?.logo_url}
              title={data.title}
              subtitle={generateSubtitle()}
              salary={generateCompensation(data!)}
              step={1}
            />
            <ContentLayout>
              <div className="grow w-full">
                <MatchedSkills skills={vacancySkills} />
                <div
                  className="mt-6 mb-12 w-full md:w-2/3 mx-auto"
                  dangerouslySetInnerHTML={{ __html: body }}
                />
              </div>
              <ActionButtons
                isLoading={isLoading}
                isDarkMode={isDarkMode}
                accepted={accepted}
                btnOneLabel="I'm interested"
                btnTwoLabel="This doesn't suit me"
                onAccept={() => handleResponse(true)}
                onReject={() => setAccepted(false)}
              />
            </ContentLayout>
          </div>
          <Transition
            show={accepted === false}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {accepted === false && (
              <div className="absolute">
                <Popup
                  onSubmit={() => handleResponse(false)}
                  onCancel={() => setAccepted(true)}
                />
              </div>
            )}
          </Transition>
        </MainLayout>
      </Transition>
    );
  }

  return (
    <MainLayout>
      <Head title="Job Description" />
      <Transition
        show
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-screen h-screen pb-40 flex justify-center items-center">
          <Spinner size="lg" />
        </div>
      </Transition>
    </MainLayout>
  );
};
