import { ReactNode, useState, useEffect, useContext } from "react";
import { DataContext } from "@/contexts";
import { useNavigate, useParams } from "react-router-dom";
import { useDataFromApi } from "@/api/auth-query";

export const DataProvider = ({ children }: { children: ReactNode }) => {
  const [confidence, setConfidence] = useState<any[]>([]);
  const [newSkills, setNewSkills] = useState<any[]>([]);

  const { uuid } = useParams();
  const navigate = useNavigate();

  const { application, vacancy } = useDataFromApi();

  useEffect(() => {
    if (
      uuid &&
      application &&
      vacancy?.data.vacancy.public_status === "closed"
    ) {
      navigate(`/prospect/${uuid}/job-closed`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, application, vacancy]);

  return (
    <DataContext.Provider
      value={{
        confidence,
        newSkills,
        setConfidence,
        setNewSkills,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};
