import { useEffect, useRef } from "react";
import { AppProvider } from "@/providers/appProvider";
import { AppRoutes } from "@/routes";
import { toast } from "react-toastify";
import { ThemeProvider } from "@/providers/themeProvider";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@/api/react-query";
import * as Sentry from "@sentry/react";

const env = process.env.REACT_APP_ENV || "development";

Sentry.init({
  dsn: "https://b5ea16b32d8f48a498843ddf23825732@o4504933892358144.ingest.sentry.io/4504933992366080",
  integrations:
    env !== "development"
      ? [new Sentry.BrowserTracing()]
      : [new Sentry.Integrations.Breadcrumbs({ console: false })],
  tracesSampleRate: 0.25,
  release: "taas-prospect@1.0.0",
});

function App() {
  const toastIdRef = useRef<any>(null);
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      const handleMessage = (event: any) => {
        if (event.data.type === "VERSION") {
          const currentVersion = process.env.REACT_APP_VERSION;
          const receivedVersion = event.data.version;

          const storedVersion =
            localStorage.getItem("appVersion") || currentVersion;

          if (storedVersion !== receivedVersion) {
            if (toastIdRef.current !== null) {
              toast.dismiss(toastIdRef.current);
            }

            toastIdRef.current = toast.info(
              "A new version of the app is available. Please refresh the page.",
              {
                autoClose: false,
              }
            );

            localStorage.setItem("appVersion", receivedVersion);
          }
        }
      };

      navigator.serviceWorker.addEventListener("message", handleMessage);

      const requestVersion = () => {
        try {
          if (navigator.serviceWorker && navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({
              type: "GET_VERSION",
            });
          }
        } catch (error) {
          console.log(error);
        }
      };

      // Request the version every minute
      setInterval(requestVersion, 60 * 1000);

      // Request the version on page load
      requestVersion();

      return () => {
        navigator.serviceWorker.removeEventListener("message", handleMessage);
      };
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <AppProvider>
          <AppRoutes />
        </AppProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
