import { useMutation } from "@tanstack/react-query"
import { MutationConfig } from '@/api/react-query';
import { toast } from 'react-toastify'
import axios from 'axios';
import apiUrl from '@/config'

type SubmitMessageProps = {
    message: string;
    uuid: string;
}

export const submitMessage = ({ message, uuid }: SubmitMessageProps) => {
    return axios.patch(`${apiUrl}/candidates/${uuid}`, {
        candidate_application: { message_to_recruiter: message }
    })
    .catch(error => {
        console.log(error.response?.data)
        return { error }
    })
}

type UseSubmitMessageOptions = {
    config?: MutationConfig<typeof submitMessage>;
}

export const useSubmitMessage = ({ config }: UseSubmitMessageOptions) => {
    const mutation = useMutation({
        onError: (error) => {
            if (error instanceof Error) {
                console.log(error)
                toast.error(error.message)
            } else {
                console.log(error)
                toast.error("An unexpected error occurred")
            }
        },
        ...config,
        mutationFn: submitMessage
    })

    return { ...mutation, isLoading: mutation.isLoading, isSuccess: mutation.isSuccess }
}