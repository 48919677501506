import clsx from "clsx";
import { useTheme } from "@/contexts";

type Props = {
  logo?: string | null;
  name?: string | null;
};

export const Logo = ({ logo, name }: Props) => {
  const { isDarkMode } = useTheme();
  return (
    <div
      className={clsx("mb-2 rounded-lg bg-white w-fit m-auto", {
        "!bg-transparent": isDarkMode,
      })}
    >
      {logo ? (
        <div
          className={clsx("bg-white p-2 rounded-lg", {
            "bg-white": isDarkMode,
          })}
        >
          <img src={logo} alt={name!} width="80" className="rounded" />
        </div>
      ) : (
        <div
          className={clsx("bg-slate-400 p-4 rounded-lg", {
            "!bg-slate-700": isDarkMode,
          })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-10 h-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
            />
          </svg>
        </div>
      )}
    </div>
  );
};
