export const getStatus = (application: any, vacancy: any) => {
    return {
        id: application?.data?.id,
        interested_position: application?.data?.interested_position,
        interested_company: application?.data?.interested_company,
        interested_salary: application?.data?.interested_salary,
        user_phone: application?.data?.phone,
        user_email: application?.data?.email,
        user_status: application?.data?.status,
        job_status: vacancy?.data?.vacancy?.publid_status!
    }
}