import { useTheme } from "@/contexts";
import clsx from "clsx";

export const ErrorPage = ({ error }: any) => {
  const { isDarkMode } = useTheme();
  return (
    <div
      className={clsx(
        "relative bg-gradient-to-b from-[#804FEE] to-[#372896] min-h-screen h-full",
        { "from-[#0D2338] to-[#17132C]": isDarkMode }
      )}
    >
      <div className="w-full h-screen px-6 pb-40 text-center flex flex-col justify-center items-center text-white text-2xl">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={0.8}
          stroke="currentColor"
          className="w-40 h-40"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
          />
        </svg>
        <h1 className="mt-4 text-3xl font-semibold">Authentication failed</h1>
        <p className="text-xl whitespace-nowrap">{error && error}</p>
        <p className="mt-4 text-base w-full md:w-1/3">
          Please make sure you have entered a correct prospect ID and check your
          internet connection. If the problem persists please contact our
          support team.
        </p>
      </div>
    </div>
  );
};
