import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDataFromApi } from '@/api/auth-query';
import { useSubmitData } from '../api/submitData';
import { useWithdrawCandidate } from '../api/withdrawCandidate';
import { AxiosError } from 'axios';

export const useJobDescription = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { application, vacancy, company, vacancySkills } = useDataFromApi();
  const { mutateAsync: mutateAsyncData, isLoading } = useSubmitData({});
  const { mutateAsync: mutateAsyncWithdraw } = useWithdrawCandidate({});

  const [data, setData] = useState<any>(null);
  const [companyData, setCompanyData] = useState<any>(null);
  const [body, setBody] = useState<any>(null);
  const [accepted, setAccepted] = useState<boolean | any>(null);
  const [error, setError] = useState<any>()

  useEffect(() => {
    if (vacancy) {
      if (vacancy?.data?.vacancy?.public_status! === 'closed') {
        navigate(`/prospect/${uuid}/job-closed`)
      } else {
        setData(vacancy?.data.vacancy);
        setBody(vacancy?.data.vacancy.description_rt);
      }
    }
    if (vacancy) {
      setData(vacancy?.data.vacancy);
      setBody(vacancy?.data.vacancy.description_rt);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy]);

  useEffect(() => {
    if (company) {
      setCompanyData(company?.data.employer);
    }
  }, [company]);

  const handleResponse = async (accepted: boolean) => {
    if (!uuid) {
      return;
    }
    try {
      accepted ? setAccepted(true) : setAccepted(false)
      await mutateAsyncData({
        interested_position: accepted,
        uuid,
      });
      if (!accepted) {
        await mutateAsyncWithdraw({
          vacancy_id: vacancy.data.vacancy.id,
          uuid,
        });
      }
      
      navigate(
        `/prospect/${uuid}${accepted ? '/company-profile' : '/feedback-withdraw'}`,
      );
    } catch (error) {
      if (error instanceof Error || (error as AxiosError).isAxiosError) {
        setError(error)
        return error;
      } else {
        const newError = new Error(error as any)
        setError(newError)
        return newError
      }
    }
  };

  return {
    data,
    companyData,
    body,
    isLoading,
    handleResponse,
    accepted,
    setAccepted,
    applicationError: application?.error,
    vacancySkills: vacancySkills?.data || [],
    error,
  };
};
