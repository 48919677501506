import React, { useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { DropdownProps } from "../../types";
import { useTheme } from "@/contexts";
import clsx from "clsx";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const Dropdown = ({
  id,
  expertise,
  onSelect,
  isNewSkill,
}: DropdownProps) => {
  const [value, setValue] = useState<string | null>(
    expertise ? expertise.charAt(0).toUpperCase() + expertise.slice(1) : null
  );
  const { isDarkMode } = useTheme();

  useEffect(() => {
    value && onSelect(id, value, isNewSkill!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="flex flex-col w-full sm:w-1/3">
      <label className="font-medium text-gray-500 text-sm">Expertise</label>
      <Menu
        as="div"
        className={clsx("relative", { "!border-black": isDarkMode })}
      >
        <div>
          <Menu.Button
            className={clsx(
              "inline-flex w-full justify-between rounded-sm whitespace-nowrap border border-gray-300 bg-white px-4 mt-1 text-sm hover:bg-gray-50 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500",
              {
                "!bg-transparent !hover:bg-slate-700 !border-slate-600":
                  isDarkMode,
              }
            )}
          >
            <p className={isDarkMode ? "text-slate-200" : "text-gray-800"}>
              {!value ? "Please select" : value}
            </p>
            <ChevronDownIcon
              className="-mr-1 ml-1 my-auto h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-10 mt-2 w-full origin-top-right text-left rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={(e: any) => {
                      setValue(e.target.innerHTML);
                    }}
                    className={classNames(
                      active && isDarkMode
                        ? "bg-indigo-600 text-white"
                        : active && !isDarkMode
                        ? "bg-indigo-600 text-white"
                        : isDarkMode
                        ? "bg-slate-700 text-white"
                        : "text-gray-800",
                      "flex px-4 py-3 text-sm cursor-pointer w-full text-left"
                    )}
                  >
                    Beginner
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={(e: any) => {
                      setValue(e.target.innerHTML);
                    }}
                    className={classNames(
                      active && isDarkMode
                        ? "bg-indigo-600 text-white"
                        : active && !isDarkMode
                        ? "bg-indigo-600 text-white"
                        : isDarkMode
                        ? "bg-slate-700 text-white"
                        : "text-gray-800",
                      "flex px-4 py-3 text-sm cursor-pointer w-full text-left"
                    )}
                  >
                    Competent
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={(e: any) => {
                      setValue(e.target.innerHTML);
                    }}
                    className={classNames(
                      active && isDarkMode
                        ? "bg-indigo-600 text-white"
                        : active && !isDarkMode
                        ? "bg-indigo-600 text-white"
                        : isDarkMode
                        ? "bg-slate-700 text-white"
                        : "text-gray-800",
                      "flex px-4 py-3 text-sm cursor-pointer w-full text-left"
                    )}
                  >
                    Expert
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
