import { useMutation } from "@tanstack/react-query"
import { MutationConfig } from '@/api/react-query';
import { toast } from 'react-toastify'
import axios from 'axios';
import apiUrl from '@/config'

type Props = {
    uuid: string;
    source: string;
}

const sources = ["invite", "followup", "reminder"]

export const triggerUnsubscribe = ({ uuid, source }: Props) => {
    if (!sources.includes(source)) {
        throw new Error("Failed to unsubscribe Invalid source: " + source + " - expected invite/followup/reminder");
    }

    const unsubscribeData = {
        candidate: {
            unsubscribe_source: source
        }
    }

    return axios.patch(`${apiUrl}/candidates/${uuid}/unsubscribe`, unsubscribeData)
        .catch((error) => {
            return { error }
        })
}

type UseTriggerUnsubscribeOptions = {
    config?: MutationConfig<typeof triggerUnsubscribe>;
}

export const useTriggerUnsubscribe = ({ config }: UseTriggerUnsubscribeOptions) => {
    const mutation = useMutation({
        onError: (error: any) => {
            if (error instanceof Error) {
                console.log(error)
                toast.error(error.message)
            } else {
                console.log(error)
                toast.error("An unexpected error occurred")
            }
        },
        ...config,
        mutationFn: triggerUnsubscribe
    })

    return { ...mutation, isLoading: mutation.isLoading }
}