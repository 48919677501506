import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDataFromApi } from '@/api/auth-query';
import { useSubmitData } from '../api/submitData';
import { useWithdrawCandidate } from '../api/withdrawCandidate';
import { toast } from 'react-toastify';

export const useSalaryProfile: any = () => {
const { application, company, vacancy } = useDataFromApi();
const { mutateAsync: mutateAsyncData, isLoading } = useSubmitData({});
const { mutateAsync: mutateAsyncWithdraw } = useWithdrawCandidate({});

  const { uuid } = useParams();
  const navigate = useNavigate();

  const [companyData, setCompanyData] = useState<any>(null);
  const [vacancyData, setVacancyData] = useState<any>(null);
  const [body, setBody] = useState<any>(null);
  const [accepted, setAccepted] = useState<boolean | any>(null);

  useEffect(() => {
    if (vacancy) {
      if (vacancy?.data?.vacancy?.public_status! === "closed") {
        navigate(`/prospect/${uuid}/job-closed`);
        setVacancyData(vacancy?.data.vacancy);
        setBody(vacancy?.data.vacancy.benefits_rt);
      } else {
        setVacancyData(vacancy?.data.vacancy);
        setBody(vacancy?.data.vacancy.benefits_rt);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy]);

  useEffect(() => {
    if (company) {
      setCompanyData(company?.data.employer);
    }
  }, [company]);

  const handleResponse = async (accepted: boolean) => {
    if (uuid) {
      try {
        accepted ? setAccepted(true) : setAccepted(false)
        await mutateAsyncData({
          interested_salary: accepted,
          uuid,
        });
        if (!accepted) {
          await mutateAsyncWithdraw({
            vacancy_id: vacancy.data.vacancy.id,
            uuid,
          });
        }
        navigate(
          `/prospect/${uuid}${accepted ? "/confirm-intro" : "/feedback-withdraw"}`
        );
      } catch (error) {
        if (error instanceof Error) {
          console.error(error);
          toast.error(error.message);
        } else {
          console.error(error);
          toast.error("An unexpected error occurred");
        }
      }
    }
  };

  return {
    vacancyData,
    companyData,
    body,
    isLoading,
    handleResponse,
    accepted,
    setAccepted,
    applicationError: application?.error,
  };
};
