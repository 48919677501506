import React from "react";
import clsx from "clsx";
import { useTheme } from "@/contexts";

type SkillTagsProps = {
  skills: any[];
  isDarkMode: boolean;
};

export const SkillTags: React.FC<SkillTagsProps> = ({ skills }) => {
  const { isDarkMode, theme } = useTheme();
  const bgColor =
    theme.secondary && !isDarkMode ? theme.secondary + "10" : "#804FED10";
  const textColor =
    theme.secondary && !isDarkMode ? theme.secondary : "#804FED";

  return (
    <div className="flex justify-center flex-wrap gap-4 my-10">
      {skills?.map((skill: any) => (
        <div
          key={skill.vacancy_skill.id}
          className={clsx(
            "text-[#804FED] bg-[#804FED]/10 font-medium rounded-full px-6 py-2",
            {
              "text-[#c7adff]": isDarkMode,
            }
          )}
          style={{ backgroundColor: bgColor, color: textColor }}
        >
          {skill.vacancy_skill.name}
        </div>
      ))}
    </div>
  );
};
