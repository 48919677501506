import { Button } from "@/components/Elements/Button";
import { CheckIcon } from "@/components/Icons";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { ErrorPage } from "@/components/Layout/Error";
import { Head } from "@/components/Head";
import { MainLayout } from "@/components/Layout/MainLayout";
import { Spinner } from "@/components/Elements/Spinner";
import { Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { useTheme } from "@/contexts";
import { useDataFromApi } from "@/api/auth-query";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ConfirmIntro = () => {
  const { isDarkMode } = useTheme();
  const { application, vacancy } = useDataFromApi();

  const navigate = useNavigate();
  const { uuid } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (vacancy?.data?.vacancy?.public_status! === "closed") {
      navigate(`/prospect/${uuid}/job-closed`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy]);

  if (application?.error?.message) {
    const errorMessage = application.error.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={application?.error.message} />;
  }

  if (vacancy) {
    return (
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
      >
        <MainLayout currentStep={4}>
          <Head title="Confirm your skills" />
          <div className="md:my-auto md:pb-40">
            <ContentLayout transparent>
              <div className="grow md:grow-0 flex flex-col justify-center mb-10">
                <div className="text-white flex flex-col items-center justify-between">
                  <CheckIcon />
                  <h3 className="mt-3 mx-auto text-center font-[tiemposheadline-bold]">
                    Confirm your skills to book a call
                  </h3>
                </div>
                <div className="text-white text-center w-full md:w-2/3 mx-auto">
                  <div className="py-4">
                    <p>
                      We’re glad you’re interested in this role. Confirm your
                      skills on the next page (takes 2 minutes).
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col items-center">
                <Button
                  onClick={() => navigate(`/prospect/${uuid}/confirm-skills`)}
                  variant={isDarkMode ? "primaryDark" : "primary"}
                  className="text-white mb-4 md:mb-8"
                  size="lg"
                  ariaLabel="button"
                >
                  Confirm skills
                </Button>
              </div>
            </ContentLayout>
          </div>
        </MainLayout>
      </Transition>
    );
  }

  return (
    <MainLayout>
      <Head title="Confirm your skills" />
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full h-screen pb-40 flex justify-center items-center">
          <Spinner size="lg" data-testid="loading" />
        </div>
      </Transition>
    </MainLayout>
  );
};
