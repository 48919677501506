import { useQuery } from '@tanstack/react-query'
import { ExtractFnReturnType, QueryConfig } from '@/api/react-query';
import axios from 'axios'
import apiUrl from '@/config'

export const getBookedInterviews = async ({ uuid }: { uuid?: string }): Promise<any | undefined> => {
    return await axios.get(`${apiUrl}/candidates/${uuid}/candidate_interview_slots`)
    .catch(error => {
        return { error }
    })
}

type QueryFnType = typeof getBookedInterviews

type UseInterviewsOptions = {
    uuid?: string | undefined,
    config?: QueryConfig<QueryFnType>
}

export const useBookedInterviews = ({ uuid, config }: UseInterviewsOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['bookedInterviews', uuid],
        queryFn: () => getBookedInterviews({ uuid }),
        onError: (error) => {
            console.log(error)
            throw error
        },
        enabled: !!uuid,
        ...config,
    })
}