import { Button } from "@/components/Elements/Button";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { Dropdown } from "./components/Dropdown";
import { ErrorPage } from "@/components/Layout/Error";
import { Head } from "@/components/Head";
import { Hero } from "@/components/Layout/Hero";
import { Input } from "./components/Input";
import { MainLayout } from "@/components/Layout/MainLayout";
import { Skill } from "./components/Skill";
import { Slider } from "./components/Slider";
import { Skill as SkillType } from "./types";
import { Spinner } from "@/components/Elements/Spinner";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";
import { useTheme, useData } from "@/contexts";
import { useDataFromApi } from "@/api/auth-query";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSubmitSkills } from "./api/submitSkills";
import clsx from "clsx";
import React from "react";

export const ConfirmSkills = () => {
  const { isDarkMode } = useTheme();
  const { application, vacancy, candidateSkills, vacancySkills } =
    useDataFromApi();
  const { confidence, setConfidence, newSkills, setNewSkills } = useData();

  const [skillsData, setSkillsData] = React.useState<any[]>([]);
  const { mutateAsync, isLoading } = useSubmitSkills({});

  const { uuid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (vacancy?.data?.vacancy?.public_status! === "closed") {
      navigate(`/prospect/${uuid}/job-closed`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (candidateSkills && vacancySkills) {
      let originalSkillsArr: any = [];
      let newSkillsArr: any = [];

      if (
        candidateSkills &&
        candidateSkills.data &&
        candidateSkills.data.length &&
        typeof candidateSkills?.data !== "string"
      ) {
        if (
          !candidateSkills?.data?.some((skill: any) => {
            return skill?.candidate_vacancy_skill?.vacancy_skill_id;
          })
        ) {
          vacancySkills?.data?.map((skill: any) => {
            return originalSkillsArr.push(skill.vacancy_skill);
          });
        } else {
          candidateSkills?.data?.forEach((skill: any) => {
            if (
              skill.candidate_vacancy_skill &&
              skill.candidate_vacancy_skill.vacancy_skill &&
              !!skill.candidate_vacancy_skill.vacancy_skill_id
            ) {
              originalSkillsArr.push(
                skill.candidate_vacancy_skill.vacancy_skill
              );
            } else {
              newSkillsArr.push(skill.candidate_vacancy_skill);
            }
          });
        }
      } else {
        vacancySkills?.data?.map((skill: any) => {
          return originalSkillsArr.push(skill.vacancy_skill);
        });
      }
      setSkillsData(originalSkillsArr);
      setNewSkills([...newSkillsArr]);
      initConfidence(originalSkillsArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateSkills, vacancySkills]);

  useEffect(() => {
    initConfidence(skillsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillsData]);

  const initConfidence = (skills: any) => {
    skills &&
      skills.length &&
      setConfidence(
        skills?.map((skill: any) => {
          return {
            vacancy_skill_id: skill?.id,
            confidence:
              candidateSkills?.data?.find(
                (item: any) =>
                  item?.candidate_vacancy_skill?.vacancy_skill_id === skill.id
              )?.candidate_vacancy_skill.confidence ||
              confidence?.find((item) => item.vacancy_skill_id === skill?.id)
                ?.confidence ||
              50,
          };
        })
      );
  };

  const changeExpertise = (id: number, value: string) => {
    let newArr = [...newSkills!];
    newArr.find((item) => item.custom_id === id || item.id === id)!.experience =
      value === "Beginner" ? "basic" : value.toLowerCase();
    setNewSkills(newArr);
  };

  const changeConfidence = (id: number, value: number) => {
    let newArr = confidence?.map((el) =>
      el.vacancy_skill_id === id
        ? { vacancy_skill_id: id, confidence: value }
        : el
    );
    setConfidence(newArr!);
  };

  const handleSelectSkill = (id: number, newSkill: string) => {
    let newArr = [...newSkills!];
    newArr!.find(
      (item) => item.custom_id === id || item.id === id
    )!.custom_skill_name = newSkill;
    setNewSkills([...newSkills!]);
  };

  const handleAddSkill = () => {
    setNewSkills([
      ...newSkills!,
      {
        custom_id: Math.floor(Math.random() * 1000000),
        custom_skill_name: null,
        experience: null,
        confidence: null,
      },
    ]);
  };

  const handleRemoveSkill = (name: string) => {
    setNewSkills(
      newSkills!.filter((item: SkillType) => {
        return item.custom_skill_name !== name;
      })
    );
  };

  const handleSubmit = () => {
    const newSkillsSubmit = newSkills?.map(({ custom_id, ...rest }) => rest);

    const updateSkills = async () => {
      if (uuid && confidence && newSkillsSubmit) {
        try {
          await mutateAsync({
            skills: confidence,
            newSkills: newSkillsSubmit,
            uuid,
          });
          navigate(`/prospect/${uuid}/confirm-match`);
        } catch (error) {
          if (error instanceof Error) {
            throw new Error(error.message);
          } else {
            throw new Error("An unknown error has occured");
          }
        }
      }
    };
    updateSkills();
  };

  const isDisabled = (): boolean => {
    if (newSkills?.length) {
      if (
        newSkills?.some(
          (skill) =>
            skill.custom_skill_name === null || skill.experience === null
        )
      ) {
        return true;
      } else return false;
    } else return false;
  };

  if (application?.error?.message) {
    const errorMessage = application.error.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={application?.error.message} />;
  }

  if (application && candidateSkills && vacancySkills) {
    return (
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
      >
        <MainLayout currentStep={5}>
          <Head title="Confirm skills" />
          <div className="flex flex-col">
            <Hero
              title="Confirm Skills"
              description="Your online profile shows you have the following skills relevant to this role. Please confirm your level of expertise and add further skills if you wish."
            />
            <ContentLayout>
              <div className="grow mx-auto w-full">
                <div className="mb-12 w-full lg:w-2/3 mx-auto mt-4">
                  {skillsData &&
                    !!skillsData.length &&
                    skillsData?.map((skill, index) => (
                      <div key={index} className="pb-10">
                        <div className="flex justify-between items-center">
                          <Skill name={skill?.name} />
                        </div>
                        <Slider
                          id={skill?.id}
                          value={
                            skill?.confidence ||
                            confidence?.find(
                              (el) => el.vacancy_skill_id === skill?.id
                            )?.confidence!
                          }
                          onValueChange={changeConfidence}
                        />
                      </div>
                    ))}
                </div>

                <p className="text-lg mb-20 w-full md:w-2/3 text-center mx-auto">
                  If you have any more skills that you think may be suitable for
                  this role you can add them here.
                </p>

                <div className="w-full lg:w-2/3 mx-auto">
                  {newSkills &&
                    !!newSkills?.length &&
                    newSkills?.map((skill, index) => (
                      <div key={index} className="relative pb-10">
                        <div className="flex flex-col sm:flex-row flex-nowrap justify-between items-center gap-4 sm:gap-2">
                          <Input
                            id={skill.custom_id || skill.id}
                            handleSelectSkill={handleSelectSkill}
                            value={skill.custom_skill_name}
                          />
                          <Dropdown
                            id={skill.custom_id || skill.id}
                            onSelect={changeExpertise}
                            expertise={skill.experience}
                            isNewSkill
                          />
                        </div>
                        <button
                          className="absolute right-0 mt-2 font-medium text-sm text-red-500"
                          onClick={() =>
                            handleRemoveSkill(skill.custom_skill_name!)
                          }
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                </div>
              </div>

              <Button
                variant={isDarkMode ? "secondaryDark" : "secondary"}
                onClick={handleAddSkill}
                className={clsx(
                  "text-[#c13fbb] disabled:bg-transparent disabled:text-gray-400/70 disabled:border-gray-400/70 mt-10",
                  { "!text-slate-50": isDarkMode }
                )}
                size="lg"
                disabled={newSkills!?.length >= 5}
              >
                Add a new skill
              </Button>
              <Button
                onClick={handleSubmit}
                variant={isDarkMode ? "primaryDark" : "primary"}
                disabled={isDisabled()}
                className="mb-12"
                size="lg"
                isLoading={isLoading}
                aria-label="Confirm experience"
              >
                Confirm experience
              </Button>
            </ContentLayout>
          </div>
        </MainLayout>
      </Transition>
    );
  }

  return (
    <MainLayout>
      <Head title="Confirm skills" />
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full h-screen pb-40 flex justify-center items-center">
          <Spinner size="lg" />
        </div>
      </Transition>
    </MainLayout>
  );
};
