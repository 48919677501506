import { ApplicationStatus, ApplicationType } from "@/types";
import { ErrorPage } from "@/components/Layout/Error";
import { Spinner } from "@/components/Elements/Spinner";
import { toast } from "react-toastify";
import { useTheme } from "@/contexts";
import { useDataFromApi, getUserUuid } from "@/api/auth-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

export const Auth = () => {
  const [uuid, setUuid] = useState<string | null>(
    getUserUuid() as string | null
  );
  const { isDarkMode } = useTheme();
  const { application, vacancy, bookedInterviews } = useDataFromApi();
  const navigate = useNavigate();

  const applicationTypes = ["schedule_conversation", "ats_link", "call_back"];

  const navigateWithUUID = (route: string) => navigate(route);

  const getRouteBasedOnInitialState = (application: ApplicationType) => {
    if (!application.interested_position) {
      return `/prospect/${uuid}/job-description`;
    } else if (!application.interested_company) {
      return `/prospect/${uuid}/company-profile`;
    } else if (!application.interested_salary) {
      return `/prospect/${uuid}/salary-profile`;
    } else {
      return `/prospect/${uuid}/confirm-skills`;
    }
  };

  const navigateBasedOnStatus = (
    status: ApplicationStatus,
    application: ApplicationType
  ) => {
    const routeMap: Record<ApplicationStatus, string> = {
      status_invited: getRouteBasedOnInitialState(application),
      status_engaging: getRouteBasedOnInitialState(application),
      status_conversation: "select-dates",
      status_closed_rejected: "unsuccessful",
      status_closed_finished: "finished",
      status_closed_withdrawn: "feedback-withdraw",
      status_feedback: "feedback-final",
    };
    return navigateWithUUID(routeMap[status] || "job-description");
  };

  const navigateBasedOnPhoneLength = (phoneLength: number) => {
    return phoneLength === 0
      ? navigateWithUUID("change-phone")
      : navigateWithUUID("confirm");
  };

  useEffect(() => {
    application?.error && toast.error(application?.error?.response?.data.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);

  useEffect(() => {
    setUuid(getUserUuid() as string | null);

    if (!uuid) return;

    try {
      if (vacancy && application) {
        if (
          window.location.pathname === `/prospect/${uuid}/unsubscribe` ||
          `/prospect/${uuid}/feedback-final`
        ) {
          navigate(window.location.pathname);
        }
        if (
          (vacancy?.data.vacancy.public_status === "open" ||
            typeof vacancy?.data.vacancy.public_status === "undefined") &&
          application?.data
        ) {
          if (
            bookedInterviews?.data?.length > 0 &&
            ["status_invited", "status_conversation"].includes(
              application?.data?.status
            )
          ) {
            navigateBasedOnPhoneLength(application?.data?.phone?.length);
          } else if (
            application?.data?.status === "status_conversation" &&
            (!application?.data?.email.length ||
              !application?.data?.phone.length)
          ) {
            navigateWithUUID("confirm-match");
          } else {
            if (applicationTypes.includes(application?.data?.applying_by)) {
              if (application?.data?.applying_by === "sceduling_conversation") {
                return navigateBasedOnStatus(
                  application?.data?.status,
                  application?.data
                );
              } else {
                navigate(`/prospect/${uuid}/confirm`);
              }
            } else {
              return navigateBasedOnStatus(
                application?.data?.status,
                application?.data
              );
            }
          }
        } else {
          if (application?.data?.status === "status_conversation") {
            if (applicationTypes.includes(application?.data?.applying_by)) {
              if (application?.data?.applying_by === "sceduling_conversation") {
                return navigateBasedOnStatus(
                  application?.data?.status,
                  application?.data
                );
              } else {
                navigate(`/prospect/${uuid}/confirm`);
              }
            } else {
              return navigateBasedOnStatus(
                application?.data?.status,
                application?.data
              );
            }
          } else navigateWithUUID("job-closed");
        }
      }
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error has occured";
      toast.error(errorMessage);
      throw new Error(errorMessage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, application, vacancy, bookedInterviews]);

  if (application?.error?.message) {
    console.log(application.error);
    return <ErrorPage error={application.error.message} />;
  }

  return (
    <div
      className={clsx(
        "bg-gradient-to-b from-[#804FEE] to-[#372896] min-h-screen h-full",
        { "from-[#0D2338] to-[#17132C]": isDarkMode }
      )}
    >
      <div className="w-full h-screen pb-30 flex justify-center items-center">
        <Spinner size="lg" data-testid="loading" />
      </div>
    </div>
  );
};
