import clsx from "clsx";
import { DarkModeButton } from "../Elements/DarkModeButton";
import { useTheme } from "@/contexts";
import { useParams, useNavigate } from "react-router-dom";

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-[1.6rem] h-[1.6rem]"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
      />
    </svg>
  );
};

const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-[1.8rem] h-[1.8rem]"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

const CalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-[1.8rem] h-[1.8rem]"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
      />
    </svg>
  );
};

const ChevronLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={3}
      stroke="currentColor"
      className="w-5 h-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 19.5L8.25 12l7.5-7.5"
      />
    </svg>
  );
};

type Props = {
  step?: number;
};

export const Navigation = ({ step }: Props) => {
  const { isDarkMode, theme } = useTheme();
  const { uuid } = useParams();

  const navigate = useNavigate();

  const color = !isDarkMode && theme.tertiary ? theme.tertiary : "#bdee4f";

  const navigation = [
    {
      step: 1,
      name: "Job Description",
      navTitle: "Discover",
      link: `/prospect/${uuid}/job-description`,
      current: true,
      icon: <SearchIcon />,
    },
    {
      step: 1,
      name: "Job Description",
      navTitle: null,
      link: `/prospect/${uuid}/job-description`,
      current: true,
      icon: null,
    },
    {
      step: 2,
      name: "Company",
      navTitle: null,
      link: `/prospect/${uuid}/company-profile`,
      current: false,
      icon: null,
    },
    {
      step: 3,
      name: "Salary and Benefits",
      navTitle: null,
      link: `/prospect/${uuid}/salary-profile`,
      current: false,
      icon: null,
    },
    {
      step: 4,
      name: "Confirm skills",
      navTitle: "Confirm skills",
      link: `/prospect/${uuid}/confirm-intro`,
      current: false,
      icon: <CheckIcon />,
    },
    {
      step: 5,
      name: "Confirm skills",
      navTitle: null,
      link: `/prospect/${uuid}/confirm-skills`,
      current: false,
      icon: null,
    },
    {
      step: 6,
      name: "You're a match",
      navTitle: null,
      link: `/prospect/${uuid}/confirm-match`,
      current: false,
      icon: null,
    },
    {
      step: 7,
      name: "Select dates",
      navTitle: null,
      link: `/prospect/${uuid}/select-dates`,
      current: false,
      icon: null,
    },
    {
      step: 8,
      name: "Change phone",
      navTitle: "Book a conversation",
      link: `/prospect/${uuid}/change-phone`,
      current: false,
      icon: <CalendarIcon />,
      children: [
        {
          step: 8,
          name: "What happens next",
          navTitle: "Book a conversation",
          link: `/prospect/${uuid}/confirm`,
          current: false,
          icon: <CalendarIcon />,
        },
      ],
    },
  ];

  const handlePreviousClick = () => {
    if (step && step > 1) {
      const item = navigation.find((item) => item.step === step - 1);
      item && navigate(item.link);
    }
  };

  return (
    <>
      <div
        className={`relative bg-transparent w-full md:w-1/2 xl:w-1/3 m-auto flex md:gap-2 justify-center pt-2 pb-6 sm:p-6 mb-6 ${
          !step && "hidden !p-0 !m-0"
        }`}
      >
        {step && step !== 1 && step <= 7 && (
          <button
            onClick={handlePreviousClick}
            className="absolute text-white top-3 sm:top-[1.8rem] -left-10 z-10"
          >
            <ChevronLeft />
          </button>
        )}

        {navigation.map((item, index) => {
          const determineTextColor = () => {
            if (
              (item.step <= step! || step! >= 7) &&
              !(
                (item.step <= step! && isDarkMode) ||
                (step! >= 7 && isDarkMode)
              )
            ) {
              return color;
            } else if (
              (item.step <= step! && isDarkMode) ||
              (step! >= 7 && isDarkMode)
            ) {
              return "#00ff87";
            }
            return "rgb(248 250 252 / 0.3)";
          };

          const textColor = determineTextColor();

          return (
            <div
              className={clsx(
                "relative text-slate-50/30 flex flex-col justify-center items-center px-1",
                {
                  "!px-[15px]": item.icon,
                }
              )}
              style={{ color: textColor }}
              key={index}
            >
              {item.icon ? (
                item.icon
              ) : (
                <div
                  className={clsx("w-1.5 h-1.5 rounded-full bg-slate-50/30")}
                  style={{ backgroundColor: textColor }}
                ></div>
              )}

              {item.navTitle && (
                <p
                  className={clsx(
                    "absolute top-6 text-center text-xs tracking-wide whitespace-nowrap",
                    {
                      "!break-words !whitespace-normal":
                        index === navigation.length - 1,
                    }
                  )}
                >
                  {item.navTitle}
                </p>
              )}
            </div>
          );
        })}
      </div>
      <div className="absolute top-3 sm:top-6 right-5 z-20">
        <DarkModeButton />
      </div>
    </>
  );
};
