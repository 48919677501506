import { AxiosError } from "axios";
import { Button } from "@/components/Elements/Button";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { Date } from "./components/Date";
import { ErrorPage } from "@/components/Layout/Error";
import { Event, Day } from "./types";
import { Head } from "@/components/Head";
import { Hero } from "@/components/Layout/Hero";
import { MainLayout } from "@/components/Layout/MainLayout";
import { Spinner } from "@/components/Elements/Spinner";
import { Recruiter } from "@/components/Elements/Recruiter";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";
import { useTheme } from "@/contexts";
import { useDataFromApi } from "@/api/auth-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useSubmitInterviewSlot } from "./api/submitInterviewSlot";
import clsx from "clsx";
import moment from "moment";

export const SelectDates = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const queryClient = useQueryClient();

  const {
    application,
    vacancy,
    freeInterviewsQuery,
    bookedInterviews,
    recruiter,
    isSuccess,
  } = useDataFromApi({ fetchFreeInterviews: true });

  const { mutateAsync } = useSubmitInterviewSlot({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (application && vacancy) {
      if (vacancy?.data?.vacancy?.public_status! === "closed") {
        navigate(`/prospect/${uuid}/job-closed`);
      } else if (vacancy?.data?.vacancy?.public_status! === "open") {
        if (
          !application?.data?.phone.length ||
          !application?.data?.email.length
        ) {
          navigate(`/prospect/${uuid}/confirm-match`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy, application]);

  const [error, setError] = useState<any>(null);
  const [selectedDates, setSelectedDates] = useState<any[]>([]);
  const [primaryDate, setPrimaryDate] = useState<any>(null);
  const [events, setEvents] = useState(
    freeInterviewsQuery?.data?.data &&
      !!freeInterviewsQuery?.data?.data?.length &&
      freeInterviewsQuery?.data?.data
        .sort(
          (a: any, b: any) =>
            moment(a.start_time).startOf("day").valueOf() -
            moment(b.start_time).startOf("day").valueOf()
        )
        .slice(0, 10)
  );
  const [days, setDays] = useState<any>(null);
  const [showAll, setShowAll] = useState(
    freeInterviewsQuery?.data?.data &&
      freeInterviewsQuery?.data?.data?.length <= 10
      ? true
      : false
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean | null>(null);

  useEffect(() => {
    if (
      freeInterviewsQuery?.data?.data &&
      !!freeInterviewsQuery?.data?.data?.length
    ) {
      const events = freeInterviewsQuery?.data?.data?.sort(
        (a: any, b: any) =>
          moment(a.start_time).startOf("day").valueOf() -
          moment(b.start_time).startOf("day").valueOf()
      );

      // events && setEvents(showAll ? events : events.slice(0, 10));
      setEvents(events);
    }
  }, [freeInterviewsQuery, showAll]);

  useEffect(() => {
    if (events && !!events.length) {
      const record = events?.reduce((acc: any, event: any) => {
        const day = moment(event.start_time).format("dddd D MMMM YYYY");
        if (!acc[day]) {
          acc[day] = { day, events: [] };
        }
        acc[day].events.push({
          ...event,
        });
        return acc;
      }, {} as Record<string, Day>);

      if (record) {
        const values = Object.values(record);
        setDays(showAll ? values?.slice(0, 7) : values?.slice(0, 3));
      }
    }
  }, [events, showAll]);

  useEffect(() => {
    if (error) toast.error(error);
  }, [error]);

  const onDateSelect = (event: any, primary: boolean) => {
    if (selectedDates && !!selectedDates?.length) {
      !selectedDates.find((date) => date.id === event.id) &&
        setSelectedDates([
          ...selectedDates,
          {
            ...event,
            primary,
          },
        ]);
    } else {
      !selectedDates.find((date) => date.id === event.id) &&
        setSelectedDates([
          {
            ...event,
            primary,
          },
        ]);
    }
  };

  const onDateRemove = (event: any) => {
    setSelectedDates(
      selectedDates?.filter((date: any) => {
        return date.id !== event.id;
      })
    );
    if (primaryDate?.id === event.id) {
      setPrimaryDate(null);
    }
  };

  const togglePrimary = (event: any) => {
    const isSelected = selectedDates?.some((date) => date.id === event.id);
    const isPrimary = primaryDate?.id === event.id;
    const reset = selectedDates?.map((date) => ({
      ...date,
      primary: false,
    }));

    const updatedDates = reset.map((date) => ({
      ...date,
      primary: date.id === event.id,
    }));

    if (isSelected) {
      if (!isPrimary) {
        setSelectedDates(updatedDates);
        setPrimaryDate(event);
      } else {
        setPrimaryDate(null);
        setSelectedDates(updatedDates?.filter((date) => date.id !== event.id));
      }
    } else {
      onDateSelect(event, true);
      setSelectedDates([...(updatedDates || []), { ...event, primary: true }]);
      setPrimaryDate(event);
    }
  };

  const isDateDisabled = (selected: boolean, event: any) => {
    if (selectedDates) {
      if (
        selectedDates?.length >= 3 &&
        !selected &&
        event.id !== primaryDate?.id
      ) {
        return true;
      } else return false;
    } else {
      return false;
    }
  };

  async function handleSubmit() {
    if (uuid && !!selectedDates?.length) {
      setIsSubmitting(true);

      const primaryDate = selectedDates?.find((date) => date.primary);
      const backupDates = selectedDates?.filter((date) => !date.primary);

      try {
        await mutateAsync({
          interview_id: primaryDate.id,
          candidate_id: uuid,
          preferred: primaryDate.primary,
        });

        const backupResults = await Promise.all(
          backupDates?.map((date) => {
            return mutateAsync({
              interview_id: date.id,
              candidate_id: uuid,
              preferred: date.primary,
            }).catch((error) => {
              setIsSubmitting(false);
              setSelectedDates([]);
              queryClient.invalidateQueries(["freeInterviews"]);
              toast.error(
                `Error booking backup slot ${moment(date.start_time).format(
                  "dddd D MMMM YYYY hh:mm A"
                )}`
              );
              console.error(
                `Error booking backup slot ${moment(date.start_time).format(
                  "dddd D MMMM YYYY hh:mm A"
                )}: `,
                error
              );
              return { error, date: date.id };
            });
          })
        );

        const failedBackupSlots = backupResults.filter(
          (result: any) => result?.error
        );

        if (failedBackupSlots.length > 0) {
          setIsSubmitting(false);
          setSelectedDates([]);
          setError(
            `Failed to book ${
              failedBackupSlots.length
            } backup slot(s): ${failedBackupSlots
              .map((slot: any) => slot?.id)
              .join(", ")}`
          );
        } else {
          navigate(`/prospect/${uuid}/confirm`);
        }
      } catch (error) {
        setIsSubmitting(false);
        setSelectedDates([]);
        queryClient.invalidateQueries(["freeInterviews"]);

        if (error instanceof AxiosError) {
          if (error.response?.status === 500) {
            toast.error(
              "Error booking primary slot. Internal server error (500). Please try again."
            );
          } else {
            toast.error(
              "Error booking primary slot. This slot may already have been booked or you have already booked a slot."
            );
          }
        } else {
          console.log(error);
        }
        console.error("Error booking primary slot:", error);
      }
    }
  }

  useEffect(() => {
    if (bookedInterviews && bookedInterviews.data?.length > 0) {
      navigate(`/prospect/${uuid}/confirm`);
    }

    if (
      bookedInterviews &&
      freeInterviewsQuery?.data?.data &&
      bookedInterviews.data?.length === 0 &&
      freeInterviewsQuery.data?.data?.length === 0
    ) {
      navigate(`/prospect/${uuid}/no-availability`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, bookedInterviews, freeInterviewsQuery, uuid, navigate]);

  if (application?.error?.message) {
    const errorMessage = application.error.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={application?.error.message} />;
  }

  if (
    application &&
    !bookedInterviews?.data?.length &&
    freeInterviewsQuery?.data?.data?.length &&
    recruiter
  ) {
    return (
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
      >
        <MainLayout currentStep={7}>
          <Head title="Conversation with the recruiter" />
          <div className="flex flex-col">
            <Hero
              title="Conversation with the recruiter"
              description="Please select a preferred time for your call. It will be a phone call (details confirmed by email)."
              step={7}
            />
            <ContentLayout>
              <div className="mb-12 w-full">
                <h2 className="text-2xl text-center font-[tiemposheadline-bold] font-semibold mb-6">
                  Meet your recruiter
                </h2>
                {recruiter?.data && (
                  <Recruiter
                    name={recruiter.data.recruiter.name}
                    avatar={recruiter.data.recruiter.avatar_url!}
                    jobTitle={recruiter.data.recruiter.job_title}
                    message={recruiter.data.recruiter.message_to_candidates}
                    linkedIn={recruiter.data.recruiter.linkedin_url!}
                    displayMessage
                  />
                )}
              </div>
              <div className="text-center w-full md:w-2/3">
                <h5 className="font-[tiemposheadline-bold] font-semibold mb-6">
                  Select a suitable time
                </h5>
                <p>
                  Please select one preferred time and two backups if you can.
                  Use the tick to mark your preferred time.
                </p>
                <div className="flex flex-col gap-6 my-12 lg:w-1/2 xl:w-2/3 mx-auto">
                  {days !== null &&
                    Object.keys(days)?.map((day: any, index) => {
                      return (
                        <div key={index}>
                          <h5 className="text-base font-medium">
                            {days[day].day}
                          </h5>
                          <ul className="list-none m-auto text-center">
                            {days[day].events?.map(
                              (event: Event, index: number) => {
                                return (
                                  <li key={index}>
                                    <Date
                                      event={event}
                                      togglePrimary={togglePrimary}
                                      onSelect={onDateSelect}
                                      onRemove={onDateRemove}
                                      isDisabled={isDateDisabled}
                                      isPrimary={event.id === primaryDate?.id}
                                      isSelected={selectedDates.find(
                                        (date) => date.id === event.id
                                      )}
                                    />
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      );
                    })}
                </div>
              </div>
              <Button
                onClick={handleSubmit}
                variant={isDarkMode ? "primaryDark" : "primary"}
                disabled={
                  !selectedDates.length ||
                  !selectedDates.some((date) => date.primary)
                }
                size="lg"
                isLoading={isSubmitting!}
                aria-label="Send preferred times"
              >
                Send preferred times
              </Button>
              <Button
                variant={isDarkMode ? "secondaryDark" : "secondary"}
                className={clsx("text-indigo-800 mb-8", {
                  "!text-indigo-50": isDarkMode,
                })}
                onClick={() => {
                  !showAll && freeInterviewsQuery?.data?.data?.length > 10
                    ? setShowAll(!showAll)
                    : navigate(`/prospect/${uuid}/no-suitable-slots`);
                }}
                size="lg"
              >
                None of these times suit me
              </Button>
            </ContentLayout>
          </div>
        </MainLayout>
      </Transition>
    );
  }

  return (
    <MainLayout>
      <Head title="Job Description" />
      <Transition
        show
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-screen h-screen pb-40 flex justify-center items-center">
          <Spinner size="lg" />
        </div>
      </Transition>
    </MainLayout>
  );
};
