import { Button } from "@/components/Elements/Button";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { ErrorPage } from "@/components/Layout/Error";
import { Head } from "@/components/Head";
import { MainLayout } from "@/components/Layout/MainLayout";
import { Popup } from "@/components/Elements/Popup";
import { TimeIcon } from "@/components/Icons";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { useTheme } from "@/contexts";
import { useDataFromApi } from "@/api/auth-query";
import { useNavigate, useParams } from "react-router-dom";
import { useWithdrawCandidate } from "./api/withdrawCandidate";

export const NoAvailableSlots = () => {
  const { isDarkMode } = useTheme();
  const { application, vacancy } = useDataFromApi();
  const { mutateAsync } = useWithdrawCandidate({});

  const navigate = useNavigate();
  const { uuid } = useParams();

  const [show, setShow] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (vacancy?.data?.vacancy?.public_status! === "closed") {
      navigate(`/prospect/${uuid}/job-closed`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy]);

  if (application?.error?.message) {
    const errorMessage = application.error.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={application?.error.message} />;
  }

  const handleResponse = async () => {
    if (!uuid) {
      return;
    }
    try {
      await mutateAsync({
        vacancy_id: vacancy?.data.vacancy.id,
        uuid,
      });
      navigate(`/prospect/${uuid}/feedback-withdraw`);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
      } else {
        console.error(error);
      }
    }
  };

  return (
    <Transition
      show
      appear
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      className="w-full h-full"
    >
      <MainLayout>
        <Head title="No time slots available" />
        <div className="flex flex-col md:my-auto md:pb-40">
          <ContentLayout transparent>
            <div className="grow md:grow-0 flex flex-col justify-center -mt-40 md:mt-0 mb-10">
              <div className="text-white flex flex-col items-center justify-between">
                <TimeIcon />
                <h3 className="font-[tiemposheadline-bold] mt-3 text-center">
                  No time slots available
                </h3>
              </div>
              <div className="text-white text-center w-full md:w-2/3 mx-auto">
                <div className="py-4">
                  <p>
                    Thank you for confirming your skills & experience. There are
                    no available time slots to select for a chat, however we
                    will send you an email as soon as new ones are available.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col items-center mb-3">
              <Button
                onClick={() => setShow(true)}
                variant={isDarkMode ? "secondaryDark" : "secondary"}
                size="lg"
                className="text-white"
              >
                I'm no longer interested
              </Button>
            </div>
          </ContentLayout>
        </div>
        <Transition
          show={show}
          enter="transition-opacity duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {show && (
            <div className="absolute">
              <Popup
                onSubmit={() => handleResponse()}
                onCancel={() => setShow(false)}
              />
            </div>
          )}
        </Transition>
      </MainLayout>
    </Transition>
  );
};
