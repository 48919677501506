import { useMutation } from "@tanstack/react-query"
import { MutationConfig } from '@/api/react-query';
import { toast } from 'react-toastify'
import axios from 'axios';
import apiUrl from '@/config'

type Feedback = {
    conversation_contacted?: boolean | null;
    conversation_positive?: boolean | null;
    conversation_understood_industry?: boolean | null;
    conversation_recruiter_rating?: boolean | null;
    conversation_consider_company?: boolean | null;
}

type SubmitFeedbackProps = {
    feedback: Feedback;
    uuid: string;
}

export const submitFeedbackWithdrawn = ({ feedback, uuid }: SubmitFeedbackProps) => {
    return axios.patch(`${apiUrl}/candidates/${uuid}/conversation_withdrawn`, {
        candidate: {...feedback}
    })
    .catch(error => {
        console.log(error.response?.data)
        return error.response?.data
    })
}

type UseSubmitFeedbackOptions = {
    config?: MutationConfig<typeof submitFeedbackWithdrawn>;
}

export const useSubmitFeedbackWithdrawn = ({ config }: UseSubmitFeedbackOptions) => {
    const mutation = useMutation({
        onError: (error) => {
            if (error instanceof Error) {
                console.log(error)
                toast.error(error.message)
            } else {
                console.log(error)
                toast.error("An unexpected error occurred")
            }
        },
        ...config,
        mutationFn: submitFeedbackWithdrawn
    })

    return { ...mutation, isLoading: mutation.isLoading, isSuccess: mutation.isSuccess }
}