import { Transition } from "@headlessui/react";
import { useTheme } from "@/contexts";
import clsx from "clsx";

type PopupTypes = {
  onCancel: () => void;
  onSubmit: () => void;
};

export const Popup = ({ onCancel, onSubmit }: PopupTypes) => {
  const { isDarkMode } = useTheme();

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <Transition
        show
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-slate-950 bg-opacity-90 transition-opacity z-10"></div>
      </Transition>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition
            show
            enter="transition-opacity duration-75"
            enterFrom="opacity-0 scale-110"
            enterTo="opacity-100 scale-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={clsx(
                "relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg",
                { "!bg-slate-800": isDarkMode }
              )}
            >
              <div
                className={clsx("bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4", {
                  "!bg-slate-800": isDarkMode,
                })}
              >
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      className="h-6 w-6 text-red-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3
                      className={clsx(
                        "text-base font-semibold leading-6 text-gray-900",
                        { "text-white": isDarkMode }
                      )}
                      id="modal-title"
                    >
                      Withdraw from application
                    </h3>
                    <div className="mt-2">
                      <p
                        className={clsx("text-sm text-gray-500", {
                          "!text-gray-300": isDarkMode,
                        })}
                      >
                        Are you sure you want to withdraw? This action cannot be
                        undone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={clsx(
                  "bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6",
                  { "bg-slate-700/50": isDarkMode }
                )}
              >
                <button
                  onClick={() => onSubmit()}
                  type="button"
                  className={clsx(
                    "inline-flex w-full justify-center rounded-full bg-red-600 px-6 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto",
                    { "bg-red-600": isDarkMode }
                  )}
                >
                  Yes
                </button>
                <button
                  onClick={() => onCancel()}
                  type="button"
                  className={clsx(
                    "mt-3 inline-flex w-full justify-center rounded-full bg-white px-6 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto",
                    { "!bg-slate-700 ring-slate-600 text-white": isDarkMode }
                  )}
                >
                  No
                </button>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
};
