import { ApplicationStatus, ApplicationType } from "@/types";
import { AuthContext } from "@/contexts";
import { getStatus } from "@/utils/getStatus";
import { getUserUuid } from "@/api/auth-query";
import { StatusType } from "@/types";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDataFromApi } from "@/api/auth-query";
import { useNavigate, useLocation } from "react-router-dom";

export const AuthProvider = ({ children }: any) => {
  const { application, vacancy, bookedInterviews } = useDataFromApi();
  const [hasExecuted, setHasExecuted] = useState(false);
  const [status, setStatus] = useState<StatusType>({});
  const [uuid, setUuid] = useState<string | null>(
    getUserUuid() as string | null
  );

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setStatus(getStatus(application, vacancy));
  }, [application, vacancy]);

  useEffect(() => {
    const getRouteBasedOnInitialState = (application: ApplicationType) => {
      if (!application.interested_position) {
        return `/prospect/${uuid}/job-description`;
      } else if (!application.interested_company) {
        return `/prospect/${uuid}/company-profile`;
      } else if (!application.interested_salary) {
        return `/prospect/${uuid}/salary-profile`;
      } else {
        return `/prospect/${uuid}/confirm-skills`;
      }
    };

    const applicationTypes = ["schedule_conversation", "ats_link", "call_back"];

    const getRouteBasedOnStatus = (
      status: ApplicationStatus,
      application: ApplicationType
    ) => {
      const routeMap: Record<ApplicationStatus, string> = {
        status_invited: getRouteBasedOnInitialState(application),
        status_engaging: getRouteBasedOnInitialState(application),
        status_conversation: `/prospect/${uuid}/select-dates`,
        status_closed_rejected: `/prospect/${uuid}/unsuccessful`,
        status_closed_finished: `/prospect/${uuid}/finished`,
        status_closed_withdrawn: `/prospect/${uuid}/feedback-withdraw`,
        status_feedback: `/prospect/${uuid}/feedback-final`,
      };

      return routeMap[status] || `/prospect/${uuid}/job-description`;
    };

    const getRouteBasedOnPhoneLength = (phoneLength: number) => {
      return phoneLength === 0
        ? `/prospect/${uuid}/change-phone`
        : `/prospect/${uuid}/confirm`;
    };

    const deriveRoute = () => {
      setUuid(getUserUuid() as string | null);

      if (!uuid) {
        return;
      }

      try {
        if (vacancy && application && bookedInterviews) {
          if (
            window.location.pathname === `/prospect/${uuid}/unsubscribe` ||
            `/prospect/${uuid}/feedback-final`
          ) {
            return window.location.pathname;
          }
          if (
            (vacancy?.data.vacancy.public_status === "open" ||
              typeof vacancy?.data.vacancy.public_status === "undefined") &&
            application?.data
          ) {
            if (
              bookedInterviews?.data?.length > 0 &&
              ["status_invited", "status_conversation"].includes(
                application?.data?.status
              )
            ) {
              return getRouteBasedOnPhoneLength(
                application?.data?.phone?.length
              );
            } else if (
              application?.data?.status === "status_conversation" &&
              (!application?.data?.email.length ||
                !application?.data?.phone.length)
            ) {
              return `/prospect/${uuid}/confirm-match`;
            } else {
              if (applicationTypes.includes(application?.data?.applying_by)) {
                if (
                  application?.data?.applying_by === "sceduling_conversation"
                ) {
                  return getRouteBasedOnStatus(
                    application?.data?.status,
                    application?.data
                  );
                } else {
                  return `/prospect/${uuid}/confirm`;
                }
              } else {
                return getRouteBasedOnStatus(
                  application?.data?.status,
                  application?.data
                );
              }
            }
          } else {
            if (application?.data?.status === "status_conversation") {
              if (applicationTypes.includes(application?.data?.applying_by)) {
                if (
                  application?.data?.applying_by === "sceduling_conversation"
                ) {
                  return getRouteBasedOnStatus(
                    application?.data?.status,
                    application?.data
                  );
                } else {
                  return `/prospect/${uuid}/confirm`;
                }
              } else {
                return getRouteBasedOnStatus(
                  application?.data?.status,
                  application?.data
                );
              }
            } else return `/prospect/${uuid}/job-closed`;
          }
        }
      } catch (error: unknown) {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "An unknown error has occured";
        toast.error(errorMessage);
        throw new Error(errorMessage);
      }
    };

    const currentRoute = location.pathname;

    const targetRoute = deriveRoute();

    if (
      application &&
      typeof targetRoute !== "undefined" &&
      currentRoute !== targetRoute &&
      !hasExecuted
    ) {
      navigate(targetRoute);
      setHasExecuted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application, vacancy, bookedInterviews, uuid]);

  return <AuthContext.Provider value={status}>{children}</AuthContext.Provider>;
};
