import { ActionButtons } from "@/components/Elements/ActionButtons";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { ErrorPage } from "@/components/Layout/Error";
import { generateCompensation } from "@/utils/generateCompensation";
import { Head } from "@/components/Head";
import { Hero } from "@/components/Layout/Hero";
import { MainLayout } from "@/components/Layout/MainLayout";
import { Popup } from "@/components/Elements/Popup";
import { Spinner } from "@/components/Elements/Spinner";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";
import { useTheme } from "@/contexts";
import { useEffect } from "react";
import { useSalaryProfile } from "./hooks/useSalaryProfile";

export const SalaryProfile = () => {
  const { isDarkMode } = useTheme();

  const {
    vacancyData,
    companyData,
    body,
    isLoading,
    accepted,
    setAccepted,
    handleResponse,
    applicationError,
  } = useSalaryProfile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (applicationError?.message) {
    const errorMessage = applicationError.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={applicationError.message} />;
  }

  if (vacancyData && companyData) {
    return (
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
      >
        <MainLayout currentStep={3}>
          <Head title="Salary and Benefits" />
          <div className="flex flex-col">
            <Hero
              showLogo
              logo={companyData?.logo_url}
              title={companyData?.name}
              step={3}
            />
            <ContentLayout>
              <div className="grow mb-12 mx-auto w-full md:w-2/3">
                <h2 className="flex justify-center font-[tiemposheadline-bold]">
                  Salary & Benefits
                </h2>
                <p className="text-lg text-center">
                  This position offers a compensation of{" "}
                  <span className="font-bold">
                    {generateCompensation(vacancyData!)}
                  </span>{" "}
                  - this is dependent upon your skills and experience.
                </p>
                <h5 className="text-center pt-6 mb-6 font-[tiemposheadline-bold]">
                  What we can offer you
                </h5>
                <div
                  className="mb-12"
                  dangerouslySetInnerHTML={{ __html: body! }}
                />
              </div>

              <ActionButtons
                isLoading={isLoading}
                isDarkMode={isDarkMode}
                accepted={accepted}
                btnOneLabel="I'm interested"
                btnTwoLabel="This doesn't suit me"
                onAccept={() => handleResponse(true)}
                onReject={() => setAccepted(false)}
              />
            </ContentLayout>
            <Transition
              show={accepted === false}
              enter="transition-opacity duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {accepted === false && (
                <div className="absolute">
                  <Popup
                    onSubmit={() => handleResponse(false)}
                    onCancel={() => setAccepted(true)}
                  />
                </div>
              )}
            </Transition>
          </div>
        </MainLayout>
      </Transition>
    );
  }

  return (
    <MainLayout>
      <Head title="Salary and Benefits" />
      <Transition
        show
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-screen h-screen pb-40 flex justify-center items-center">
          <Spinner size="lg" />
        </div>
      </Transition>
    </MainLayout>
  );
};
