import { ThemeContext } from "@/contexts";
import { useState, useEffect } from "react";
import { useDataFromApi } from "@/api/auth-query";

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [theme, setTheme] = useState<any>({
    primary: null,
    secondary: null,
    tertiary: null,
  });
  const { company } = useDataFromApi({});

  const isHexCode = (str: string) => {
    if (str.indexOf("#") === -1) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(mediaQuery.matches);

    const listener = (event: MediaQueryListEvent) =>
      setIsDarkMode(event.matches);
    mediaQuery.addEventListener("change", listener);

    return () => mediaQuery.removeEventListener("change", listener);
  }, []);

  useEffect(() => {
    if (company?.data?.employer?.primary_color) {
      setTheme((currentTheme: any) => ({
        ...currentTheme,
        primary: company?.data?.employer?.primary_color,
      }));
    }
    if (company?.data?.employer?.secondary_color) {
      setTheme((currentTheme: any) => ({
        ...currentTheme,
        secondary: !isHexCode(company?.data?.employer?.secondary_color)
          ? "#" + company?.data?.employer?.secondary_color
          : company?.data?.employer?.secondary_color,
      }));
    }
    if (company?.data?.employer?.tertiary_color) {
      setTheme((currentTheme: any) => ({
        ...currentTheme,
        tertiary: company?.data?.employer?.tertiary_color,
      }));
    }
  }, [company]);

  useEffect(() => {
    const color = theme.primary ? theme.primary : "#804FEE";
    const themeColor = document.querySelector('meta[name="theme-color"]');
    const newThemeColor = isDarkMode ? "#0a0d1c" : color;
    themeColor?.setAttribute("content", newThemeColor);
  }, [isDarkMode, theme]);

  const toggleMode = () => setIsDarkMode(!isDarkMode);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleMode, theme }}>
      {children}
    </ThemeContext.Provider>
  );
};
