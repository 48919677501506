import { Button } from "@/components/Elements/Button";
import { CheckIcon, InfoIcon } from "@/components/Icons";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { ErrorPage } from "@/components/Layout/Error";
import { Fade } from "@mui/material";
import { Head } from "@/components/Head";
import { Hero } from "@/components/Layout/Hero";
import { MainLayout } from "@/components/Layout/MainLayout";
import { Spinner } from "@/components/Elements/Spinner";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";
import { useTheme } from "@/contexts";
import { useDataFromApi } from "@/api/auth-query";
import { useParams, useNavigate } from "react-router";
import { useState, useEffect } from "react";
import { useSubmitFeedback } from "./api/submitFeedback";
import { useUnwithdraw } from "./api/unwithdraw";
import clsx from "clsx";
import React from "react";

interface FormData {
  feedback_qualifications: boolean;
  feedback_not_interesting: boolean;
  feedback_company: boolean;
  feedback_salary: boolean;
  feedback_time_slots: boolean;
  feedback_other: boolean;
  feedback_details: string;
}

export const FeedbackWithdraw = () => {
  const { isDarkMode } = useTheme();
  const { uuid } = useParams();
  const { application, vacancy } = useDataFromApi();
  const { mutateAsync, isLoading, isSuccess } = useSubmitFeedback({});
  const { mutateAsync: mutateUnwithdraw, isLoading: isUnwithdrawLoading } =
    useUnwithdraw({});
  const navigate = useNavigate();

  const [formData, setFormData] = useState<FormData>({
    feedback_qualifications: false,
    feedback_not_interesting: false,
    feedback_company: false,
    feedback_salary: false,
    feedback_time_slots: false,
    feedback_other: false,
    feedback_details: "",
  });

  useEffect(() => {
    if (vacancy?.data?.vacancy?.public_status! === "closed") {
      navigate(`/prospect/${uuid}/job-closed`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };

  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const updateFeedback = async () => {
      if (uuid) {
        try {
          await mutateAsync({ feedback: formData, uuid });
        } catch (error) {
          if (error instanceof Error) {
            console.error(error);
            toast.error(error.message);
          } else {
            console.error(error);
            toast.error("An unexpected error occurred");
          }
        }
      }
    };
    updateFeedback();
  };

  const handleUnwithdraw = () => {
    const unwithdraw = async () => {
      if (uuid) {
        try {
          await mutateUnwithdraw({
            uuid,
            vacancy_id: application?.data?.vacancy?.uuid,
          }).then((response) => {
            if (response.status === 200) {
              navigate(`/prospect/${uuid}/job-description`);
            }
          });
        } catch (error) {
          if (error instanceof Error) {
            console.error(error);
            toast.error(error.message);
          } else {
            console.error(error);
            toast.error("An unexpected error occurred");
          }
        }
      }
    };
    unwithdraw();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateTextArea = (text: string) => {
    const length = text.trim().length;
    return length >= 10 && length <= 200;
  };

  const isDisabled = !(
    formData.feedback_qualifications ||
    formData.feedback_not_interesting ||
    formData.feedback_company ||
    formData.feedback_salary ||
    formData.feedback_time_slots ||
    formData.feedback_other ||
    validateTextArea(formData.feedback_details)
  );

  if (application?.error?.message) {
    const errorMessage = application.error.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={application?.error.message} />;
  }

  if (isSuccess) {
    return (
      <MainLayout>
        <Head
          title="Your feedback has been sent to us"
          description="Cielo TaaS"
        />
        <Fade
          in
          timeout={400}
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <div className="mx-6 mb-6 grow w-full md:w-1/3">
            <div className="flex flex-col items-center gap-1 w-full md:w-1/3 text-center m-auto mt-10 text-white">
              <CheckIcon />
              <h3 className="font-[tiemposheadline-bold]">
                Your feedback has been sent to us
              </h3>
              <p>
                Thank you for sending us your feedback. Based on your provided
                input, we will try to send you more suitable roles in the
                future.
              </p>
            </div>
          </div>
        </Fade>
      </MainLayout>
    );
  } else if (!isSuccess && vacancy) {
    return (
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
      >
        <MainLayout>
          <Head title="How we can help you" description="Cielo TaaS" />
          <div>
            <Hero title="How we can help you" icon={<InfoIcon />} />
            <ContentLayout>
              <div className="flex flex-col grow mx-6 mb-6 w-full">
                <div
                  className={clsx(
                    "flex flex-col w-full md:w-2/3 text-center mx-auto mt-4 text-black",
                    { "text-white": isDarkMode }
                  )}
                >
                  <p className="p-0">
                    We’re sorry if this role or company doesn’t suit what you’re
                    looking for. If you could let us know what was lacking, we
                    will be more likely to be able to send you more suitable
                    roles in the future.
                  </p>
                  <p className="p-0 pb-4">
                    Or if you wish to re-enter the application, you can do so by
                    clicking the button below.
                  </p>
                </div>
                <form
                  className={clsx(
                    "flex flex-col grow w-full md:w-2/3 m-auto mt-4 text-black",
                    { "text-white": isDarkMode }
                  )}
                  onSubmit={handleSubmit}
                >
                  <div className="flex flex-col grow gap-6 mb-10">
                    <div className="flex items-center gap-3">
                      <input
                        type="checkbox"
                        name="feedback_qualifications"
                        id="feedback_qualifications"
                        checked={formData.feedback_qualifications}
                        onChange={handleCheckboxChange}
                        className="w-4 h-4 cursor-pointer"
                      />
                      <label
                        htmlFor="feedback_qualifications"
                        className="cursor-pointer"
                      >
                        The role doesn’t match my qualifications
                      </label>
                    </div>
                    <div className="flex items-center gap-3">
                      <input
                        type="checkbox"
                        name="feedback_not_interesting"
                        id="feedback_not_interesting"
                        checked={formData.feedback_not_interesting}
                        onChange={handleCheckboxChange}
                        className="w-4 h-4 cursor-pointer"
                      />
                      <label
                        htmlFor="feedback_not_interesting"
                        className="cursor-pointer"
                      >
                        The role doesn’t seem interesting to me
                      </label>
                    </div>
                    <div className="flex items-start gap-3">
                      <input
                        type="checkbox"
                        name="feedback_company"
                        id="feedback_company"
                        checked={formData.feedback_company}
                        onChange={handleCheckboxChange}
                        className="min-w-[16px] min-h-[16px] mt-1 cursor-pointer"
                      />
                      <label
                        htmlFor="feedback_company"
                        className="cursor-pointer"
                      >
                        The company doesn’t interest me, doesn’t reflect my
                        values or suit my needs{" "}
                      </label>
                    </div>
                    <div className="flex items-center gap-3">
                      <input
                        type="checkbox"
                        name="feedback_salary"
                        id="feedback_salary"
                        checked={formData.feedback_salary}
                        onChange={handleCheckboxChange}
                        className="w-4 h-4 cursor-pointer !bg-gray-800"
                      />
                      <label
                        htmlFor="feedback_salary"
                        className="cursor-pointer"
                      >
                        The salary and/or benefits don’t suit me
                      </label>
                    </div>
                    <div className="flex items-center gap-3">
                      <input
                        type="checkbox"
                        name="feedback_time_slots"
                        id="feedback_time_slots"
                        checked={formData.feedback_time_slots}
                        onChange={handleCheckboxChange}
                        className="w-4 h-4 cursor-pointer"
                      />
                      <label
                        htmlFor="feedback_time_slots"
                        className="cursor-pointer"
                      >
                        None of the time slots are suitable
                      </label>
                    </div>
                    <div className="flex items-center gap-3">
                      <input
                        type="checkbox"
                        name="feedback_other"
                        id="feedback_other"
                        checked={formData.feedback_other}
                        onChange={handleCheckboxChange}
                        className="w-4 h-4 cursor-pointer"
                      />
                      <label
                        htmlFor="feedback_other"
                        className="cursor-pointer"
                      >
                        Other
                      </label>
                    </div>
                    <div className="flex flex-col mb-6">
                      <textarea
                        name="feedback_details"
                        placeholder="Add more details (optional)"
                        value={formData.feedback_details!}
                        onChange={handleTextAreaChange}
                        maxLength={200}
                        className={clsx(
                          "bg-slate-100 text-black font-light rounded-sm w-full min-h-[150px] mt-4 px-3 py-2",
                          {
                            "!bg-gray-950/30 !text-[#ffffff]": isDarkMode,
                          }
                        )}
                      />
                      <p
                        className={clsx("text-sm text-gray-500/70", {
                          "text-gray-400/80": isDarkMode,
                        })}
                      >
                        {formData.feedback_details.length}/200 characters
                      </p>
                    </div>
                  </div>
                  <Button
                    type="submit"
                    size="lg"
                    disabled={isDisabled}
                    isLoading={isLoading}
                  >
                    Submit feedback
                  </Button>
                  <Button
                    size="lg"
                    variant={isDarkMode ? "secondaryDark" : "secondary"}
                    disabled={
                      application?.data?.status !== "status_closed_withdrawn"
                    }
                    isLoading={isUnwithdrawLoading}
                    onClick={handleUnwithdraw}
                  >
                    Re-enter application
                  </Button>
                </form>
              </div>
            </ContentLayout>
          </div>
        </MainLayout>
      </Transition>
    );
  }

  return (
    <MainLayout>
      <Head title="Job Description" />
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-screen h-screen pb-40 flex justify-center items-center">
          <Spinner size="lg" />
        </div>
      </Transition>
    </MainLayout>
  );
};
