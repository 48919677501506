import { useEffect, useState } from "react";
import { SliderProps } from "../../types";
import { Slider as MuiSlider } from "@mui/material";
import { useTheme, useData } from "@/contexts";

const marks = [
  { value: 1, label: "None" },
  { value: 20 },
  { value: 40 },
  { value: 60 },
  { value: 80 },
  { value: 100, label: "Expert" },
];

export const Slider = ({ id, onValueChange, isNewSkill }: SliderProps) => {
  const [sliderValue, setSliderValue] = useState(50);
  const { confidence, setConfidence } = useData();
  const { isDarkMode, theme } = useTheme();

  const thumbStyle =
    theme.secondary && !isDarkMode ? theme.secondary : "#E95DCD";
  const railStyle =
    theme.secondary && !isDarkMode ? theme.secondary : "#FFAFE9";
  const sliderStyle =
    theme.secondary && !isDarkMode ? theme.secondary : "#c13fbb";
  const markStyle =
    theme.secondary && !isDarkMode ? theme.secondary : "#dd5fba";

  const handleSliderChange = (value: number) => {
    setSliderValue(value);
    let newArr = confidence?.map((el) =>
      el.vacancy_skill_id === id
        ? { vacancy_skill_id: id, confidence: value }
        : el
    );
    setConfidence(newArr!);
  };

  useEffect(() => {
    setSliderValue(
      confidence?.find((item) => item.vacancy_skill_id === id)?.confidence || 50
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onValueChange(
      id,
      confidence?.find((item) => item.vacancy_skill_id === id)?.confidence ||
        50,
      isNewSkill
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="my-8">
      <label className="font-medium text-gray-500 text-sm relative -top-3">
        How confident are you with this skill?
      </label>
      <MuiSlider
        aria-label="How confident are you with this skill?"
        min={1}
        value={sliderValue}
        marks={marks}
        size="medium"
        onChange={(event, value) => {
          handleSliderChange(value as number);
        }}
        sx={{
          "& .MuiSlider-thumb": {
            backgroundColor: thumbStyle,
          },

          "& .MuiSlider-rail": {
            backgroundColor: railStyle,
            height: "6px",
          },

          "& .MuiSlider-track": {
            backgroundColor: sliderStyle,
            borderColor: sliderStyle,
            height: "6px",
          },

          "& .MuiSlider-markLabel[data-index='0']": {
            left: "17px !important",
            color: "#64748b",
          },
          "& .MuiSlider-markLabel[data-index='5']": {
            left: "calc(100% - 20px) !important",
            color: "#64748b",
          },
          "& .MuiSlider-mark": {
            backgroundColor: markStyle,
          },
          "& .MuiSlider-markActive": {
            backgroundColor: "#fff",
          },
        }}
      />
    </div>
  );
};
