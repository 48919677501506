import { convertCurrencyCodeToSymbol } from "./convertCurrencyCodeToSymbol";

export const generateCompensation = (data: any) => {
    const {
      hourly_compensation,
      hourly_compensation_max,
      compensation,
      compensation_max,
      compensation_type,
      currency
    } = data || {};

    const isRange = compensation_type === "compensation_range" || "hourly_compensation_range";
    const isHourly = Boolean(hourly_compensation || hourly_compensation_max);
    const compensationValue = hourly_compensation || compensation;
    const compensationMaxValue = hourly_compensation_max || compensation_max;
    const currencyValue = convertCurrencyCodeToSymbol(currency);
    const perHourText = isHourly ? " per hour" : "";

    return isRange && compensationMaxValue
      ? `${currencyValue}${compensationValue?.toLocaleString()} - ${compensationMaxValue?.toLocaleString()}${perHourText}`
      : `up to ${currencyValue}${compensationValue?.toLocaleString()}${perHourText}`;
  };