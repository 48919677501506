import { ContentLayout } from "@/components/Layout/ContentLayout";
import { ErrorPage } from "@/components/Layout/Error";
import { Head } from "@/components/Head";
import { MainLayout } from "@/components/Layout/MainLayout";
import { PauseIcon } from "@/components/Icons";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";
import { useDataFromApi } from "@/api/auth-query";
import { useEffect } from "react";

export const PausedPage = () => {
  const { application } = useDataFromApi();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (application?.error?.message) {
    const errorMessage = application.error.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={application?.error.message} />;
  }

  return (
    <Transition
      show
      appear
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      className="w-full h-full"
    >
      <MainLayout>
        <Head title="This job is on pause" />
        <div className="flex flex-col">
          <ContentLayout transparent>
            <div className="grow flex flex-col justify-center sm:pb-40">
              <div className="text-white flex flex-col items-center justify-between">
                <PauseIcon />
                <h3 className="font-[tiemposheadline-bold] mt-3 text-center">
                  This job is on pause
                </h3>
              </div>
              <div className="text-white text-left w-full md:w-1/2 mx-auto">
                <div className="mt-8 p-8 rounded-md border border-gray-100/25 bg-slate-400/10 shadow-2xl shadow-slate-800/20">
                  <p className="text-2xl font-medium pb-2">
                    Dear{" "}
                    {application?.data.first_name
                      ? application?.data.first_name
                      : "Candidate"}
                  </p>
                  <p>
                    The recruiter in charge of this vacancy has paused new
                    engagement for the moment so you won't be able to continue
                    with your application until engagmenet is resumed.
                  </p>
                  <p>
                    You will be able to book a conversation with the recruiter
                    after they have resumed engagement for this vacancy, so we
                    encourage you to keep checking for updates.
                  </p>
                  <p className="mt-8 -mb-2">Thanks</p>
                  <p>Cielo Digital</p>
                </div>
              </div>
            </div>
          </ContentLayout>
        </div>
      </MainLayout>
    </Transition>
  );
};
