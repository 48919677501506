import { useQuery } from '@tanstack/react-query'
import { ExtractFnReturnType, QueryConfig } from '@/api/react-query';
import axios from 'axios'
import apiUrl from '@/config'

export const getVacancySkills = async ({ vacancyId }: { vacancyId?: string }): Promise<any | null> => {
    return await axios.get(`${apiUrl}/vacancies/${vacancyId}/vacancy_skills/public`)
    .catch(error => {
        return { error }
    })
}

type QueryFnType = typeof getVacancySkills

type UseJobDescriptionOptions = {
    vacancyId: string | undefined,
    config?: QueryConfig<QueryFnType>
}

export const useVacancySkills = ({ vacancyId, config }: UseJobDescriptionOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['vacancySkills', vacancyId],
        queryFn: () => getVacancySkills({ vacancyId }),
        onError: (error) => {
            console.log(error)
            throw error
        },
        enabled: !!vacancyId,
        ...config
    })
}