import { createContext, useContext } from "react";
import { StatusType } from "@/types";

import { DataContextValue, ThemeContextValue } from "./types";

export const AuthContext = createContext<StatusType>({});

export const DataContext = createContext<DataContextValue>({
  confidence: null,
  newSkills: null,
  setConfidence: () => {},
  setNewSkills: () => {},
});

export const ThemeContext = createContext<ThemeContextValue>({
  isDarkMode: false,
  toggleMode: () => {},
  theme: {
    primary: null,
    secondary: null,
    tertiary: null,
  },
});

export const useAuth = () => useContext(AuthContext);

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const useData = () => {
  return useContext(DataContext);
};
