import React, { useState } from "react";
import { RadioButton } from "../RadioButton";
import { Button } from "@/components/Elements/Button";
import { ThumbsUpIcon, ThumbsDownIcon } from "@/components/Icons";

type Props = {
  contacted: boolean | null;
  isLoading: boolean;
  onSubmit: (feedback: any) => Promise<void> | void;
};

type FormDataProps = {
  conversation_contacted: boolean | null;
  conversation_positive: boolean | null;
  conversation_understood_industry: boolean | null;
  conversation_recruiter_rating: number | null;
  conversation_consider_company: boolean | null;
};

type RadioOption = {
  label: string;
  value: number;
};

const options: RadioOption[] = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
];

export const FeedbackForm = ({ contacted, isLoading, onSubmit }: Props) => {
  const [currentRating, setCurrentRating] = useState<number>(0);
  const [formData, setFormData] = useState<FormDataProps>({
    conversation_contacted: contacted,
    conversation_positive: null,
    conversation_understood_industry: null,
    conversation_recruiter_rating: currentRating,
    conversation_consider_company: null,
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleClick = (value: number) => {
    setCurrentRating(value);
    setFormData({ ...formData, conversation_recruiter_rating: value });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col gap-10 w-full"
      aria-label="form"
    >
      <div>
        <p className="text-lg pb-4">
          How was your conversation with the recruiter?
        </p>
        <div className="flex justify-center gap-4 mx-auto">
          <RadioButton
            label={<ThumbsUpIcon />}
            value="Good"
            checked={formData.conversation_positive === true}
            onClick={() =>
              setFormData({ ...formData, conversation_positive: true })
            }
            ariaLabel="conversation-good"
          />
          <RadioButton
            label={<ThumbsDownIcon />}
            value="Bad"
            checked={formData.conversation_positive === false}
            onClick={() =>
              setFormData({ ...formData, conversation_positive: false })
            }
            ariaLabel="conversation-bad"
          />
        </div>
      </div>
      <div>
        <p className="text-lg pb-4">The recruiter understood my industry.</p>
        <div className="flex justify-center gap-4 mx-auto">
          <RadioButton
            label={<ThumbsUpIcon />}
            value="Yes"
            checked={formData.conversation_understood_industry === true}
            onClick={() =>
              setFormData({
                ...formData,
                conversation_understood_industry: true,
              })
            }
            ariaLabel="recruiterIndustry-yes"
          />
          <RadioButton
            label={<ThumbsDownIcon />}
            value="No"
            checked={formData.conversation_understood_industry === false}
            onClick={() =>
              setFormData({
                ...formData,
                conversation_understood_industry: false,
              })
            }
            ariaLabel="recruiterIndustry-no"
          />
        </div>
      </div>
      <div>
        <p className="text-lg pb-4">Please rate your recruiter overall.</p>
        <div className="flex gap-4 justify-center">
          {options.map((item, index) => {
            return (
              <RadioButton
                key={index}
                label={item.label}
                value={item.value}
                checked={currentRating === item.value}
                onClick={() => handleClick(item.value)}
              />
            );
          })}
        </div>
      </div>
      <div>
        <p className="text-lg pb-4">
          Will you be considering this company for future positions?
        </p>
        <div className="flex justify-center gap-4 mx-auto">
          <RadioButton
            label={<ThumbsUpIcon />}
            value="Yes"
            checked={formData.conversation_consider_company === true}
            onClick={() =>
              setFormData({ ...formData, conversation_consider_company: true })
            }
            ariaLabel="consider-yes"
          />
          <RadioButton
            label={<ThumbsDownIcon />}
            value="No"
            checked={formData.conversation_consider_company === false}
            onClick={() =>
              setFormData({ ...formData, conversation_consider_company: false })
            }
            ariaLabel="consider-no"
          />
        </div>
      </div>
      <div>
        <Button
          className="mt-10 w-full lg:w-1/2 xl:w-1/3"
          type="submit"
          size="lg"
          isLoading={isLoading}
          data-testid="button"
          disabled={
            Object.values(formData).some((item) => item === null) ||
            formData?.conversation_recruiter_rating === 0
          }
        >
          Submit feedback
        </Button>
      </div>
    </form>
  );
};
