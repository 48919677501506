import { Auth } from "@/pages/Auth";
import { NotFound } from "@/pages/404";

export const publicRoutes = [
  {
    path: "/prospect/:uuid",
    element: <Auth />,
  },
  {
    path: "/*",
    element: <NotFound />,
  },
];
