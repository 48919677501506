import { ContentLayout } from "@/components/Layout/ContentLayout";
import { ErrorPage } from "@/components/Layout/Error";
import { ExclamationIcon } from "@/components/Icons";
import { Head } from "@/components/Head";
import { MainLayout } from "@/components/Layout/MainLayout";
import { Spinner } from "@/components/Elements/Spinner";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";
import { useEffect } from "react";
import { useDataFromApi } from "@/api/auth-query";
import { useNavigate, useParams } from "react-router-dom";

export const RejectedPage = () => {
  const { application, vacancy } = useDataFromApi();
  const { uuid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (vacancy?.data?.vacancy?.public_status! === "closed") {
      navigate(`/prospect/${uuid}/job-closed`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy]);

  if (application?.error?.message) {
    const errorMessage = application.error.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={application?.error.message} />;
  }

  if (vacancy) {
    return (
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
      >
        <MainLayout>
          <Head title="No time slots available" />
          <div className="flex flex-col">
            <ContentLayout transparent>
              <div className="grow flex flex-col justify-center -mt-52">
                <div className="text-white flex flex-col items-center justify-between">
                  <ExclamationIcon />
                  <h3 className="font-[tiemposheadline-bold] mt-3 text-center">
                    Thank you for your interest in this job
                  </h3>
                </div>
                <div className="text-white text-center w-full md:w-2/3 mx-auto">
                  <div className="py-4">
                    <p>
                      Unfortunately, your application is not being taken
                      forwards.
                    </p>
                  </div>
                </div>
              </div>
            </ContentLayout>
          </div>
        </MainLayout>
      </Transition>
    );
  }

  return (
    <MainLayout>
      <Head title="Thank you for your interest" />
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full h-screen pb-40 flex justify-center items-center">
          <Spinner size="lg" data-testid="loading" />
        </div>
      </Transition>
    </MainLayout>
  );
};
