import { Button } from "@/components/Elements/Button";
import { CheckIcon } from "@/components/Icons";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { ErrorPage } from "@/components/Layout/Error";
import { Head } from "@/components/Head";
import { MainLayout } from "@/components/Layout/MainLayout";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";
import { useTheme } from "@/contexts";
import { useDataFromApi } from "@/api/auth-query";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSubmitFeedbackRebook } from "./api/feedbackRebook";

export const RebookPage = () => {
  const { isDarkMode } = useTheme();
  const { application, vacancy, refetchBookedInterviews } = useDataFromApi();
  const { uuid } = useParams();
  const { mutateAsync } = useSubmitFeedbackRebook({});
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (vacancy?.data?.vacancy?.public_status! === "closed") {
      navigate(`/prospect/${uuid}/job-closed`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy]);

  if (application?.error?.message) {
    const errorMessage = application.error.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={application?.error.message} />;
  }

  const updateFeedbackRebook = async (feedback: any) => {
    if (uuid) {
      try {
        setLoading(true);
        await mutateAsync({
          feedback,
          uuid,
        }).then(() => {
          setTimeout(() => {
            refetchBookedInterviews().then(() => {
              setLoading(false);
              navigate(`/prospect/${uuid}/select-dates`);
            });
          }, 1000);
        });
      } catch (error) {
        setLoading(false);
        if (error instanceof Error) {
          console.error(error);
          toast.error(error.message);
        } else {
          console.error(error);
          toast.error("An unexpected error occurred");
        }
      }
    }
  };

  const handleRebook = () => {
    updateFeedbackRebook({ candidate_contacted: false, uuid });
  };

  return (
    <Transition
      show
      appear
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      className="w-full h-full"
    >
      <MainLayout>
        <Head title="Rebook time slots" />
        <div className="flex flex-col md:my-auto md:pb-40">
          <ContentLayout transparent>
            <div className="grow md:grow-0 flex flex-col justify-center -mt-32 md:mt-0">
              <div className="text-white flex flex-col items-center justify-between">
                <CheckIcon />
                <h3 className="font-[tiemposheadline-bold] mt-3 mx-auto text-center">
                  Rebook your dates
                </h3>
              </div>
              <div className="text-white text-center w-full md:w-2/3 mx-auto">
                <div className="py-4">
                  <p>
                    You can rebook your time slots if your schedule has changed
                    or you missed your call with the recruiter. Would you like
                    to do this now?
                  </p>
                </div>
              </div>
            </div>
            <Button
              className="mt-10 mb-4 md:mb-8 w-full lg:w-1/3"
              type="submit"
              size="lg"
              variant={isDarkMode ? "primaryDark" : "primary"}
              isLoading={loading}
              data-testid="button"
              onClick={() => handleRebook()}
            >
              Rebook time slots
            </Button>
          </ContentLayout>
        </div>
      </MainLayout>
    </Transition>
  );
};
