import { CheckIcon } from "@/components/Icons";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { ErrorPage } from "@/components/Layout/Error";
import { Head } from "@/components/Head";
import { MainLayout } from "@/components/Layout/MainLayout";
import { Spinner } from "@/components/Elements/Spinner";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";
import { useTheme } from "@/contexts";
import { useDataFromApi } from "@/api/auth-query";
import { useEffect } from "react";

export const ClosedPage = () => {
  const { application } = useDataFromApi();
  const { isDarkMode } = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (application?.error?.message) {
    const errorMessage = application.error.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={application?.error.message} />;
  }

  if (application) {
    return (
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
      >
        <MainLayout>
          <Head title="This job is closed" />
          <div>
            <ContentLayout transparent>
              <div className="grow flex flex-col justify-center sm:pb-40 pt-20">
                <div className="text-white flex flex-col items-center justify-between">
                  <CheckIcon />
                  <h3 className="font-[tiemposheadline-bold] mt-3 text-center">
                    This job is closed
                  </h3>
                </div>
                <div className="text-white text-left w-full md:w-2/3 mx-auto">
                  <div
                    className={`mt-8 p-8 rounded-md bg-gradient-to-b ${
                      isDarkMode ? "from-indigo-400/20" : "from-indigo-400/40"
                    }  to-transparent`}
                  >
                    <p className="text-2xl font-medium pb-2">
                      Dear{" "}
                      {application?.data.first_name
                        ? application?.data.first_name
                        : "Candidate"}
                      ,
                    </p>
                    <p>
                      Our records show that this job is no longer available. We
                      are sorry for the inconvenience but hope to reach out to
                      you again in the future with jobs that match your
                      skillset.
                    </p>
                    <p className="mt-6 -mb-2">Thanks,</p>
                    <p>Cielo Digital</p>
                  </div>
                </div>
              </div>
            </ContentLayout>
          </div>
        </MainLayout>
      </Transition>
    );
  }

  return (
    <MainLayout>
      <Head title="This job is closed" />
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full h-screen pb-40 flex justify-center items-center">
          <Spinner size="lg" />
        </div>
      </Transition>
    </MainLayout>
  );
};
