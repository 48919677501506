import { Button } from "@/components/Elements/Button";
import { CheckIcon, ChatIcon } from "@/components/Icons";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { ErrorPage } from "@/components/Layout/Error";
import { Fade } from "@mui/material";
import { Head } from "@/components/Head";
import { MainLayout } from "@/components/Layout/MainLayout";
import { Recruiter } from "@/components/Elements/Recruiter";
import { Spinner } from "@/components/Elements/Spinner";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";
import { useTheme } from "@/contexts";
import { useDataFromApi } from "@/api/auth-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSubmitMessage } from "./api/submitMessage";
import clsx from "clsx";

export const Message = () => {
  const [message, setMessage] = useState("");
  const { isDarkMode } = useTheme();
  const { uuid } = useParams();
  const { application, vacancy, recruiter } = useDataFromApi();
  const { mutateAsync, isLoading, isSuccess } = useSubmitMessage({});

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (vacancy?.data?.vacancy?.public_status! === "closed") {
      navigate(`/prospect/${uuid}/job-closed`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy]);

  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async () => {
    if (uuid) {
      try {
        await mutateAsync({ message, uuid });
      } catch (error) {
        if (error instanceof Error) {
          console.error(error);
          toast.error(error.message);
        } else {
          console.error(error);
          toast.error("An unexpected error occurred");
        }
      }
    }
  };

  if (application?.error?.message) {
    const errorMessage = application.error.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={application?.error.message} />;
  }

  if (isSuccess) {
    return (
      <MainLayout>
        <Head title="Your message has been sent" />
        <Fade
          in
          timeout={400}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="grow mx-6 mb-6">
            <div className="flex flex-col grow justify-center items-center gap-1 w-full md:w-1/2 text-center m-auto mt-10 text-white">
              <CheckIcon />
              <h3 className="font-[tiemposheadline-bold]">
                Your message has been sent
              </h3>
              <p>Thank you, we’ve sent your message to the recruiter.</p>
            </div>
            <div className="w-full sm:w-2/3 md:w-1/2 mx-auto mb-2 md:mb-6">
              <Button
                aria-label="Back button"
                onClick={() => navigate(`/prospect/${uuid}/confirm`)}
                className="w-full mb-6 md:mb-10"
                size="lg"
              >
                Okay
              </Button>
            </div>
          </div>
        </Fade>
      </MainLayout>
    );
  } else if (!isSuccess && vacancy && recruiter) {
    return (
      <Transition
        show={true}
        appear={true}
        enter="transition-opacity ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
      >
        <MainLayout>
          <Head title="Message to recruiter" />
          <div className="flex flex-col">
            <ContentLayout transparent>
              <div className="grow flex flex-col justify-center">
                <div className="text-white flex flex-col items-center">
                  <ChatIcon />
                  <h3 className="font-[tiemposheadline-bold] mt-3">
                    Message to recruiter
                  </h3>
                </div>
                <p className="text-white text-center mb-8 w-full sm:w-2/3 mx-auto">
                  Feel free to write anything you'd like the recruiter to know
                  before the conversation.
                </p>
                {recruiter?.data && (
                  <Recruiter
                    name={recruiter.data.recruiter.name}
                    avatar={recruiter.data.recruiter.avatar_url!}
                    jobTitle={recruiter.data.recruiter.job_title}
                    message={recruiter.data.recruiter.message}
                    linkedIn={recruiter.data.recruiter.linkedin_url!}
                  />
                )}
                <div className="flex flex-col w-full md:w-2/3 mx-auto md:px-0 mb-4">
                  <textarea
                    placeholder="Enter your message"
                    value={message}
                    onChange={handleTextAreaChange}
                    maxLength={200}
                    className={clsx(
                      "bg-white rounded-md w-full min-h-[150px] mx-auto mt-2 px-3 py-2",
                      {
                        "!bg-slate-800 !text-white": isDarkMode,
                      }
                    )}
                  />
                  <p className="text-sm text-gray-300/70 my-2">
                    {message.length}/200 characters
                  </p>
                </div>
              </div>
              <div className="w-full flex flex-col items-center mb-8">
                <Button
                  onClick={handleSubmit}
                  variant={isDarkMode ? "primaryDark" : "primary"}
                  disabled={message.length < 20 || message.length > 200}
                  size="lg"
                  isLoading={isLoading}
                >
                  Send message
                </Button>
                <Button
                  onClick={() => navigate(`/prospect/${uuid}/confirm`)}
                  variant={isDarkMode ? "secondaryDark" : "secondary"}
                  className="text-white"
                  size="lg"
                >
                  Cancel
                </Button>
              </div>
            </ContentLayout>
          </div>
        </MainLayout>
      </Transition>
    );
  }

  return (
    <MainLayout>
      <Head title="Message to recruiter" />
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full h-screen pb-40 flex justify-center items-center">
          <Spinner size="lg" data-testid="loading" />
        </div>
      </Transition>
    </MainLayout>
  );
};
