import { useEffect, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import { useTheme } from "@/contexts";

interface Event {
  start_time: string;
  end_time: string;
}

const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={0.5}
      stroke="currentColor"
      className="w-12 h-12"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

const CheckCirclcIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-12 h-12"
    >
      <path
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
        clipRule="evenodd"
      />
    </svg>
  );
};

type DateProps = {
  event: any;
  primary?: boolean | null;
  onSelect: (event: Event, primary: boolean) => void;
  onRemove: (event: Event) => void;
  togglePrimary: (event: Event) => void;
  isDisabled: (selected: boolean, event: Event) => boolean;
  isPrimary: boolean;
  isSelected: boolean;
};

export const Date = ({
  event,
  onSelect,
  onRemove,
  togglePrimary,
  isDisabled,
  isPrimary,
  isSelected,
}: DateProps) => {
  const [selected, setSelected] = useState(false);
  const { isDarkMode } = useTheme();

  const handleClick = () => {
    if (isSelected || isPrimary) {
      setSelected(false);
    } else {
      setSelected(true);
    }
  };

  const handlePrimaryClick = () => {
    togglePrimary(event);
  };

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  useEffect(() => {
    if (selected) {
      onSelect(event, isPrimary);
    } else {
      onRemove(event);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const defaultClassNames =
    "w-full px-6 py-3 bg-transparent text-[#36323F] xxs:text-xs xs:text-sm whitespace-nowrap rounded-full border border-gray-400 disabled:bg-gray-100 disabled:opacity-30 transition";

  const darkModeClassNames =
    "!border-slate-700 !text-slate-100 !disabled:bg-indigo-900/50 disabled:bg-gray-900";
  const isSelectedClassNames =
    "!bg-[#804FED]/10 !border-[#804FED]/70 !text-[#804FED] !font-medium";
  const isSelectedDarkModeClassNames =
    "!bg-[#804FED]/10 !border-indigo-400/100 !text-indigo-400 !font-medium";
  const isPrimaryClassNames =
    "!bg-[#FFE893] !border-gray-300/70 !text-slate-900 !font-medium";
  const isPrimaryDarkModeClassNames =
    "!bg-[#fbdc68] !border-gray-300/70 !text-slate-900 !font-medium";

  const classNames = clsx(defaultClassNames, {
    [darkModeClassNames]: isDarkMode,
    [isSelectedClassNames]: isSelected && !isPrimary,
    [isSelectedDarkModeClassNames]: isSelected && !isPrimary && isDarkMode,
    [isPrimaryClassNames]:
      (isPrimary && !isSelected) || (isPrimary && isSelected),
    [isPrimaryDarkModeClassNames]:
      (isPrimary && isDarkMode) || (isPrimary && isSelected && isDarkMode),
  });

  return (
    <div
      className="relative flex items-center w-full"
      aria-label={`event-${event.id}`}
    >
      <button
        onClick={handleClick}
        disabled={isDisabled(isSelected, event) || event.booked}
        className={classNames}
      >
        {moment(event?.start_time).format("h:mm A") +
          " - " +
          moment(event?.start_time)
            .add(event?.duration, "minutes")
            .format("h:mm A Z")}
      </button>
      <div className="flex group relative transition ml-2">
        <button
          aria-label={`primary-${event.id}`}
          onClick={handlePrimaryClick}
          disabled={isDisabled(isSelected, event) || event.booked}
          className={clsx("text-gray-500 disabled:opacity-20", {
            "!text-gray-700": isPrimary && isSelected && !isDarkMode,
            "!text-[#fbdc68]": isPrimary && isSelected && isDarkMode,
            "!text-[#804FED]": isSelected && !isPrimary,
            "!text-slate-500": isSelected && !isPrimary && isDarkMode,
          })}
        >
          {isPrimary ? <CheckCirclcIcon /> : <CheckIcon />}
        </button>
        <div className="hidden md:block group-hover:opacity-100 bg-[#757575] px-4 py-2 text-sm text-gray-100 rounded-sm absolute top-[0.4rem] left-14 opacity-0 m-0 mx-auto">
          <div className="relative whitespace-nowrap">
            {event.booked ? "Already booked" : "Preferred"}
            <span className="group-hover:opacity-100 bg-[#757575] w-2.5 h-2.5 text-sm text-gray-100 absolute top-[0.3rem] -left-[1.3rem] rotate-45 opacity-0 m-0 mx-auto" />
          </div>
        </div>
      </div>
    </div>
  );
};
