import { Button } from "@/components/Elements/Button";
import clsx from "clsx";
import React from "react";

type ActionButtonsProps = {
  isLoading: boolean;
  isDarkMode: boolean;
  accepted: boolean;
  btnOneLabel: string;
  btnTwoLabel: string;
  onAccept: () => void;
  onReject: () => void;
};

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  isLoading,
  isDarkMode,
  accepted,
  btnOneLabel,
  btnTwoLabel,
  onAccept,
  onReject,
}) => {
  return (
    <>
      <Button
        onClick={onAccept}
        size="lg"
        variant={isDarkMode ? "primaryDark" : "primary"}
        isLoading={isLoading && accepted}
        aria-label="I am interested"
      >
        {btnOneLabel}
      </Button>
      <Button
        size="lg"
        variant={isDarkMode ? "secondaryDark" : "secondary"}
        onClick={onReject}
        className={clsx("text-[#c13fbb] mb-6", {
          "!text-slate-50": isDarkMode,
        })}
        isLoading={isLoading && !accepted}
        aria-label="This doesn't suit me"
      >
        {btnTwoLabel}
      </Button>
    </>
  );
};
