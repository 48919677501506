import * as React from "react";
import clsx from "clsx";
import { AppProviderProps, ErrorFallbackProps } from "./types";
import { AuthProvider } from "./authProvider";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import { DataProvider } from "./dataProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "@/components/Elements/Spinner";
import { useTheme } from "@/contexts";

const ErrorFallback = ({ error }: ErrorFallbackProps) => {
  const { isDarkMode, theme } = useTheme();

  const color =
    !isDarkMode && theme.primary
      ? theme.primary
      : isDarkMode
      ? `linear-gradient(to bottom, #0c0f21, #060413)`
      : `linear-gradient(to bottom, #804FEE, #3A2F7C)`;

  if (error) {
    console.log(error as Error);
  }

  return (
    <div
      className={clsx(
        "min-h-screen h-full flex justify-center items-center px-6"
      )}
      style={{ backgroundImage: color }}
    >
      <div className="mx-auto max-w-max">
        <main>
          <div className="sm:flex items-center">
            <p className="text-4xl font-bold tracking-tight my-0 pb-2 text-[#f7d449] sm:text-5xl">
              404
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-bold tracking-tight text-gray-50 sm:text-5xl">
                  Not found
                </h1>
                <p className="mt-2 text-base text-gray-200 max-w-lg">
                  Please check the URL in the address bar and try again. If
                  you're still having issues please contact our support team.
                </p>
                {error.message && (
                  <p className="text-white font-mono">{error.message}</p>
                )}
              </div>
            </div>
          </div>
          <div className="mt-6 sm:ml-28 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
            <button
              onClick={() => window.location.reload()}
              className="inline-flex items-start rounded-md border border-transparent transition bg-indigo-600 px-8 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Refresh
            </button>
          </div>
        </main>
      </div>
    </div>
  );
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const { isDarkMode, theme } = useTheme();

  const color =
    !isDarkMode && theme.primary
      ? theme.primary
      : isDarkMode
      ? `linear-gradient(to bottom, #0c0f21, #060413)`
      : `linear-gradient(to bottom, #804FEE, #3A2F7C)`;

  return (
    <div
      className={clsx(`flex flex-col grow h-max"`)}
      style={{ background: color }}
    >
      <React.Suspense
        fallback={
          <div className="flex items-center justify-center w-screen h-screen">
            <Spinner size="xl" />
          </div>
        }
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <HelmetProvider>
            <ToastContainer
              role="alert"
              position="bottom-center"
              theme={isDarkMode ? "dark" : "light"}
              hideProgressBar
            />
            <Router>
              <AuthProvider>
                <DataProvider>{children}</DataProvider>
              </AuthProvider>
            </Router>
          </HelmetProvider>
        </ErrorBoundary>
      </React.Suspense>
    </div>
  );
};
