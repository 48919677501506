import { useQuery } from '@tanstack/react-query'
import { ExtractFnReturnType, QueryConfig } from '@/api/react-query';
import axios from 'axios'
import apiUrl from '@/config'

export const getVacancy = async ({ vacancyId }: { vacancyId?: string }): Promise<any | undefined> => {
    return await axios.get(`${apiUrl}/vacancies/${vacancyId}/details`)
    .catch(error => {
        return { error }
    })
}

type QueryFnType = typeof getVacancy

type UseVacancyOptions = {
    vacancyId?: string | undefined,
    config?: QueryConfig<QueryFnType>
}

export const useVacancy = ({ vacancyId, config }: UseVacancyOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['vacancy', vacancyId],
        queryFn: () => getVacancy({ vacancyId }),
        onError: (error) => {
            console.log(error)
            throw error
        },
        enabled: !!vacancyId,
        ...config,
    })
}