import { ExtractFnReturnType, QueryConfig } from '@/api/react-query';
import { useEffect, useState } from "react";
import { useApplication } from "@/api/getApplication";
import { useBookedInterviews } from "@/api/getBookedInterviews";
import { useCandidateSkills } from "@/api/getCandidateSkills";
import { useCompany } from "@/api/getCompany";
import { useFreeInterviews } from "@/api/getFreeInterviews";
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useRecruiter } from "@/api/getRecruiter";
import { useVacancy } from "@/api/getVacancy";
import { useVacancySkills } from "@/api/getVacancySkills";
import apiUrl from '@/config'
import axios from 'axios'

type Options = {
    fetchFreeInterviews?: boolean
}

export const getUser = async ( uuid?: string ): Promise<any | undefined> => {
    return await axios.get(`${apiUrl}/candidates/${uuid}/details`)
    .catch(error => { return error })
}

type QueryFnType = typeof getUser

type UseAuthOptions = {
    uuid?: string,
    config?: QueryConfig<QueryFnType>
}

export const useAuth = ({ uuid, config }: UseAuthOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['auth', uuid],
        queryFn: () => getUser( uuid ),
        onError: (error => {
            console.log(error)
            throw error
        }),
        enabled: uuid !== null,
        ...config
    })
}

export function getUserUuid() {
    try {
        const uuid = window.location.pathname.split("/")[2]
        return uuid;
    }
    catch (e) {
        return e;
    }
}

export const useDataFromApi = (options: Options = {}) => {
    const uuid = getUserUuid() as string
    const queryClient = useQueryClient()
    const [isLoading, setIsLoading] = useState<any>(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const [vacancyId, setVacancyId] = useState<string>()
    const [companyId, setCompanyId] = useState<string>()
    const [recruiterId, setRecruiterId] = useState<string>()

    const fetchFreeInterviews = options.fetchFreeInterviews || false
    const freeInterviewsQuery = useFreeInterviews(fetchFreeInterviews ? { uuid } : { uuid: null })

    const { data: application, isSuccess: isApplicationSuccess } = useApplication({ uuid })
    const { data: vacancy, isSuccess: isVacancySuccess } = useVacancy({ vacancyId })
    const { data: candidateSkills } = useCandidateSkills({ uuid })
    const { data: vacancySkills } = useVacancySkills({ vacancyId })
    const { data: company } = useCompany({ companyId })
    const { data: recruiter } = useRecruiter({ recruiterId })
    const { data: bookedInterviews, refetch: refetchBookedInterviews } = useBookedInterviews({ uuid })

    useEffect(() => {
        setIsLoading(false)
        setIsSuccess(false)
    }, [])

    useEffect(() => {
        isApplicationSuccess && setVacancyId(application?.data?.vacancy?.uuid)
    }, [application, isApplicationSuccess])

    useEffect(() => {
        if (isVacancySuccess) {
            setRecruiterId(vacancy?.data?.vacancy?.recruiter?.id)
            setCompanyId(vacancy?.data?.vacancy?.employer?.uuid)
        }
    }, [vacancy, isVacancySuccess])

    useEffect(() => {
        setIsLoading(queryClient.isFetching())
    }, [queryClient, queryClient.isFetching])

    return { 
        application, 
        vacancy, 
        candidateSkills, 
        vacancySkills, 
        company, 
        recruiter, 
        freeInterviewsQuery, 
        bookedInterviews, 
        refetchBookedInterviews, 
        isSuccess, 
        isLoading, 
    }

}