import { SkillProps } from "../../types";
import { useTheme } from "@/contexts";
import clsx from "clsx";

export const Skill = ({ match, name }: SkillProps) => {
  const { isDarkMode } = useTheme();
  return (
    <div>
      <label className="font-medium text-gray-500 text-sm">Skill</label>
      <div
        className={clsx(
          `flex items-center gap-1 w-fit px-6 py-2 mt-0.5 text-sm lg:text-base font-medium rounded-full`,
          {
            "!text-[#c7adff]": isDarkMode,
          }
        )}
      >
        {name}
      </div>
    </div>
  );
};
