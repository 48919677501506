import clsx from "clsx";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@/contexts";

const LinkedInIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      className="w-5 h-5"
      fill="#0B65C2"
    >
      <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
    </svg>
  );
};

type Props = {
  name?: string;
  avatar?: string;
  jobTitle?: string;
  message?: string;
  linkedIn?: string;
  displayMessage?: boolean;
};

export const Recruiter = ({
  name,
  avatar,
  jobTitle,
  message,
  linkedIn,
  displayMessage,
}: Props) => {
  const { isDarkMode } = useTheme();
  return (
    <>
      <div
        className={clsx(
          "relative flex gap-3 my-4 mx-auto w-full xs:max-w-[320px] bg-white p-6 rounded-md border border-gray-300",
          { "!bg-slate-700/50 !border-none": isDarkMode }
        )}
      >
        {linkedIn && (
          <div className="absolute top-2 right-2">
            <LinkedInIcon />
          </div>
        )}

        <Avatar sx={{ width: 60, height: 60 }} alt={name} src={avatar} />
        <div className="flex flex-col justify-center">
          <p className="font-bold text-left m-0">{name}</p>
          <p
            className={clsx("text-sm text-left text-gray-600 m-0", {
              "!text-gray-100": isDarkMode,
            })}
          >
            {jobTitle}
          </p>
          {linkedIn && (
            <a
              href={linkedIn}
              target="_blank"
              rel="noreferrer"
              className="text-sm mt-2 text-indigo-500 font-medium text-left cursor-pointer"
            >
              View LinkedIn profile
            </a>
          )}
        </div>
      </div>
      {message && displayMessage && (
        <p className="w-full md:w-2/3 text-center italic m-auto mt-6">
          "{message}"
        </p>
      )}
    </>
  );
};
