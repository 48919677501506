import { Auth } from "@/pages/Auth";
import { FeedbackWithdraw } from "@/pages/FeedbackWithdraw";
import { JobDescription } from "@/pages/JobDescription";
import { CompanyProfile } from "@/pages/CompanyProfile";
import { SalaryProfile } from "@/pages/SalaryProfile";
import { ConfirmIntro } from "@/pages/ConfirmIntro";
import { ConfirmSkills } from "@/pages/ConfirmSkills";
import { ConfirmPhoneEmail } from "@/pages/ConfirmPhoneEmail";
import { SelectDates } from "@/pages/SelectDates";
import { ChangePhone } from "@/pages/ChangePhone";
import { ChangeEmail } from "@/pages/ChangeEmail";
import { ChooseJourney } from "@/pages/ChooseJourney";
import { WhatHappensNext } from "@/pages/WhatHappensNext";
import { Message } from "@/pages/Message";
import { NoAvailableSlots } from "@/pages/NoAvailableSlots";
import { NoSuitableSlots } from "@/pages/NoSuitableSlots";
import { RejectedPage } from "@/pages/RejectedPage";
import { FinishedPage } from "@/pages/FinishedPage";
import { FeedbackFinal } from "@/pages/FeedbackFinal";
import { RebookPage } from "@/pages/RebookPage";
import { ClosedPage } from "@/pages/ClosedPage";
import { PausedPage } from "@/pages/PausedPage";
import { Unsubscribe } from "@/pages/Unsubscribe";

export const protectedRoutes = [
  {
    path: "/prospect/:uuid",
    element: <Auth />,
  },
  {
    path: "/prospect/:uuid/feedback-withdraw",
    element: <FeedbackWithdraw />,
  },
  {
    path: "/prospect/:uuid/job-description",
    element: <JobDescription />,
  },
  {
    path: "/prospect/:uuid/company-profile",
    element: <CompanyProfile />,
  },
  {
    path: "/prospect/:uuid/salary-profile",
    element: <SalaryProfile />,
  },
  {
    path: "/prospect/:uuid/confirm-intro",
    element: <ConfirmIntro />,
  },
  {
    path: "/prospect/:uuid/confirm-skills",
    element: <ConfirmSkills />,
  },
  {
    path: "/prospect/:uuid/confirm-match",
    element: <ConfirmPhoneEmail />,
  },
  {
    path: "/prospect/:uuid/select-dates",
    element: <SelectDates />,
  },
  {
    path: "/prospect/:uuid/change-phone",
    element: <ChangePhone />,
  },
  {
    path: "/prospect/:uuid/confirm",
    element: <WhatHappensNext />,
  },
  {
    path: "/prospect/:uuid/confirm/change-phone",
    element: <ChangePhone />,
  },
  {
    path: "/prospect/:uuid/confirm/change-email",
    element: <ChangeEmail />,
  },
  {
    path: "/prospect/:uuid/message",
    element: <Message />,
  },
  {
    path: "/prospect/:uuid/no-availability",
    element: <NoAvailableSlots />,
  },
  {
    path: "/prospect/:uuid/no-suitable-slots",
    element: <NoSuitableSlots />,
  },
  {
    path: "/prospect/:uuid/unsuccessful",
    element: <RejectedPage />,
  },
  {
    path: "/prospect/:uuid/finished",
    element: <FinishedPage />,
  },
  {
    path: "/prospect/:uuid/feedback-final",
    element: <FeedbackFinal />,
  },
  {
    path: "/prospect/:uuid/rebook",
    element: <RebookPage />,
  },
  {
    path: "/prospect/:uuid/job-closed",
    element: <ClosedPage />,
  },
  {
    path: "/prospect/:uuid/job-paused",
    element: <PausedPage />,
  },
  {
    path: "/prospect/:uuid/unsubscribe",
    element: <Unsubscribe />,
  },
  {
    path: "/prospect/:uuid/choose-journey",
    element: <ChooseJourney />,
  },
];
