import { useMutation } from "@tanstack/react-query"
import { MutationConfig } from '@/api/react-query';
import { toast } from 'react-toastify'
import axios from 'axios';
import apiUrl from '@/config'

export const submitSkills = ({ skills, newSkills, uuid }: any) => {
    return axios.patch(`${apiUrl}/candidates/${uuid}/candidate_vacancy_skills`, {
        candidate_vacancy_skills: [...skills, ...newSkills]
    })
    .catch(error => {
        console.log(error.response?.data)
        return error.response?.data
    })
}

type UseSubmitSkillsOptions = {
    config?: MutationConfig<typeof submitSkills>;
}

export const useSubmitSkills = ({ config }: UseSubmitSkillsOptions) => {
    const mutation = useMutation({
        onError: (error) => {
            if (error instanceof Error) {
                console.log(error)
                toast.error(error.message)
            } else {
                console.log(error)
                toast.error("An unexpected error occurred")
            }
        },
        ...config,
        mutationFn: submitSkills
    })

    return { ...mutation, isLoading: mutation.isLoading }
}