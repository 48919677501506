import { AxiosResponse } from "axios";
import clsx from "clsx";
import { Button } from "@/components/Elements/Button";
import { CheckIcon } from "@/components/Icons";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { ErrorPage } from "@/components/Layout/Error";
import { Head } from "@/components/Head";
import { MainLayout } from "@/components/Layout/MainLayout";
import { Spinner } from "@/components/Elements/Spinner";
import { Menu, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { useApplyByATS } from "./api/applyByATS";
import { useTheme } from "@/contexts";
import { useDataFromApi } from "@/api/auth-query";
import { useSubmitSelection } from "./api/applyByCallMeBack";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const ChooseJourney = () => {
  const { isDarkMode } = useTheme();
  const { application, vacancy } = useDataFromApi();
  const [selectedJourney, setSelectedJourney] = useState<string>("");
  const [value, setValue] = useState<string | null>(null);
  const btnTheme =
    "bg-[#00FF87] hover:bg-[#00FF87] border-[#00FF87] text-gray-900";
  const subtitleTheme = "text-gray-900";
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { mutateAsync: mutateAsyncATS, isLoading: isATSLoading } =
    useApplyByATS({});
  const { mutateAsync: mutateAsyncSelection, isLoading: isSelectionLoading } =
    useSubmitSelection({});

  const journeys = useMemo(
    () => [
      {
        name: "Book a time slot",
        subtext:
          "Reserve a specific time for a recruiter to discuss the role with you.",
        link: `/prospect/${uuid}/select-dates`,
        available: vacancy?.data.vacancy.apply_schedule_conversation,
      },
      {
        name: "Submit your application",
        subtext:
          "Directly send your application to the employer. Each company may have a unique application process. This will open a new link to the company website.",
        link: vacancy?.data.vacancy.ats_url,
        available: vacancy?.data.vacancy.apply_ats,
      },
      {
        name: "Flexible call window",
        subtext:
          "Select a convenient time range for a call, and our recruiter will reach out during those hours.",
        link: ``,
        available: vacancy?.data.vacancy.apply_call_me_back,
      },
    ],
    [vacancy, uuid]
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    const availableJourneys = journeys?.filter(
      (journey: any) => journey.available
    );

    if (availableJourneys.length > 1) {
      return;
    } else if (availableJourneys.length === 1) {
      if (availableJourneys[0].name !== "Flexible call window") {
        handleNavigate(availableJourneys[0].name);
      }
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeys, navigate]);

  useEffect(() => {
    if (vacancy?.data?.vacancy?.public_status! === "closed") {
      navigate(`/prospect/${uuid}/job-closed`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy]);

  if (application?.error?.message) {
    const errorMessage = application.error.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={application?.error.message} />;
  }

  const handleBtnClick = (selected: string) => {
    setSelectedJourney(selected);
  };

  const handleNavigate = async (name?: string) => {
    const journeyType = name || selectedJourney;
    const link = getJourneyLink(journeyType);
    switch (journeyType) {
      case "Submit your application":
        await handleApplicationSubmission(link);
        break;
      case "Flexible call window":
        await handleFlexibleCallWindow(link);
        break;
      default:
        navigate(link);
        break;
    }
  };

  const getJourneyLink = (journeyName: string) => {
    const journey = journeys.find((j) => j.name === journeyName);
    let link = journey?.link || "";
    if (
      journeyName === "Submit your application" &&
      !link.match(/^https?:\/\//i)
    ) {
      link = "https://" + link;
    }
    return link;
  };

  const handleApplicationSubmission = async (link: string) => {
    try {
      const response = await mutateAsyncATS({
        uuid: application?.data.id,
        vacancy_id: application?.data.vacancy.uuid,
      });
      if (isAxiosResponse(response) && (response.status === 200 || 204)) {
        navigate(`/prospect/${application?.data.id}/confirm`);
      }
    } catch (error) {
      handleNavigationError(error);
    }
  };

  const handleFlexibleCallWindow = async (link: string) => {
    try {
      const response = await mutateAsyncSelection({
        time_frame: value,
        uuid: application?.data.id,
        vacancy_id: application?.data.vacancy.uuid,
      });
      if (isAxiosResponse(response) && (response.status === 200 || 204)) {
        navigate(`/prospect/${application?.data.id}/confirm`);
      }
    } catch (error) {
      handleNavigationError(error);
    }
  };

  const isAxiosResponse = (response: any): response is AxiosResponse => {
    return response && "status" in response;
  };

  const handleNavigationError = (error: any) => {
    if (error instanceof Error) {
      toast.error(error.message);
      throw new Error(error.message);
    } else {
      throw new Error("An unknown error has occurred");
    }
  };

  if (vacancy && !isATSLoading) {
    return (
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
      >
        <MainLayout currentStep={4}>
          <Head title="Choose your journey" />
          <div className="md:my-auto md:pb-40">
            <ContentLayout transparent>
              <div className="grow md:grow-0 flex flex-col justify-center mb-10">
                <div className="text-white flex flex-col items-center justify-between">
                  <CheckIcon />
                  <h3 className="mt-3 mx-auto text-center font-[tiemposheadline-bold]">
                    How would you like to apply?
                  </h3>
                </div>
                <div className="text-white text-center w-full md:w-2/3 mx-auto">
                  <div className="py-4">
                    <p>
                      These are all available application methods for this
                      vacancy. Please choose your preferred method to apply for
                      this role. Each option is tailored to suit different needs
                      and preferences.
                    </p>
                  </div>
                  <div className="flex flex-col gap-6 mx-auto my-4 w-full md:w-2/3">
                    {journeys?.map((journey: any, index: number) => {
                      if (journey.available) {
                        return (
                          <button
                            key={index}
                            onClick={(e: any) => handleBtnClick(journey.name)}
                            className={`${
                              selectedJourney === journey.name && btnTheme
                            } border border-gray-400 rounded-md px-5 py-3 flex flex-col text-left transition duration-200`}
                          >
                            <span className="text-lg font-medium pb-0.5">
                              {journey.name}
                            </span>
                            <span
                              className={`${
                                selectedJourney === journey.name
                                  ? subtitleTheme
                                  : "text-gray-100"
                              } text-sm transition duration-200`}
                            >
                              {journey.subtext}
                            </span>
                          </button>
                        );
                      }
                      return null;
                    })}
                  </div>
                  <Transition
                    show={selectedJourney === "Flexible call window"}
                    enter="transition-all duration-500 transform"
                    enterFrom="opacity-0 translate-y-4"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition-all duration-0 transform"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-4"
                  >
                    <Menu
                      as="div"
                      className={clsx(
                        "relative w-full md:w-1/2 mx-auto pt-4 pb-8",
                        {
                          "!border-black": isDarkMode,
                        }
                      )}
                    >
                      <div>
                        <Menu.Button
                          className={clsx(
                            "inline-flex w-full justify-between rounded whitespace-nowrap border border-gray-300 bg-white px-4 mt-1 text-sm hover:bg-gray-50 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500",
                            {
                              "!bg-transparent !hover:bg-slate-700 !border-slate-600":
                                isDarkMode,
                            }
                          )}
                        >
                          <p
                            className={
                              isDarkMode ? "text-slate-200" : "text-gray-800"
                            }
                          >
                            {!value ? "Select your time window" : value}
                          </p>
                          <ChevronDownIcon
                            className="-mr-1 ml-1 my-auto h-5 w-5 text-gray-800"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={React.Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute left-0 z-10 mt-2 w-full origin-top-right text-left rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={(e: any) => {
                                    setValue(e.target.innerHTML);
                                  }}
                                  className={classNames(
                                    active && isDarkMode
                                      ? "bg-indigo-600 text-white"
                                      : active && !isDarkMode
                                      ? "bg-indigo-600 text-white"
                                      : isDarkMode
                                      ? "bg-slate-700 text-white"
                                      : "text-gray-800",
                                    "flex px-4 py-3 text-sm cursor-pointer w-full text-left"
                                  )}
                                >
                                  8:00 - 12:00
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={(e: any) => {
                                    setValue(e.target.innerHTML);
                                  }}
                                  className={classNames(
                                    active && isDarkMode
                                      ? "bg-indigo-600 text-white"
                                      : active && !isDarkMode
                                      ? "bg-indigo-600 text-white"
                                      : isDarkMode
                                      ? "bg-slate-700 text-white"
                                      : "text-gray-800",
                                    "flex px-4 py-3 text-sm cursor-pointer w-full text-left"
                                  )}
                                >
                                  12:00 - 16:00
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={(e: any) => {
                                    setValue(e.target.innerHTML);
                                  }}
                                  className={classNames(
                                    active && isDarkMode
                                      ? "bg-indigo-600 text-white"
                                      : active && !isDarkMode
                                      ? "bg-indigo-600 text-white"
                                      : isDarkMode
                                      ? "bg-slate-700 text-white"
                                      : "text-gray-800",
                                    "flex px-4 py-3 text-sm cursor-pointer w-full text-left"
                                  )}
                                >
                                  16:00 - 20:00
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </Transition>
                </div>
              </div>
              <div className="w-full flex flex-col items-center">
                <Button
                  onClick={() => handleNavigate()}
                  variant={isDarkMode ? "primaryDark" : "primary"}
                  isLoading={isATSLoading || isSelectionLoading}
                  className="text-white mb-4 md:mb-8"
                  size="lg"
                  ariaLabel="button"
                  disabled={selectedJourney == null}
                >
                  Continue
                </Button>
              </div>
            </ContentLayout>
          </div>
        </MainLayout>
      </Transition>
    );
  }

  return (
    <MainLayout>
      <Head title="Choose your journey" />
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full h-screen pb-40 flex justify-center items-center">
          <Spinner size="lg" data-testid="loading" />
        </div>
      </Transition>
    </MainLayout>
  );
};
