import clsx from "clsx";
import * as React from "react";

import { Spinner } from "@/components/Elements/Spinner";
import { useTheme } from "@/contexts";

const variants = {
  primary:
    "bg-[#d646cf] border-none text-slate-50 font-medium hover:bg-opacity-80",
  primaryDark:
    "bg-[#a437b2] border-none text-slate-50 font-medium hover:bg-opacity-80",
  secondary:
    "bg-[#d646cf] bg-opacity-0 border-[1.5px] border-[#d646cf] font-medium hover:bg-opacity-5",
  secondaryDark:
    "bg-indigo-500 bg-opacity-5 border-[1.5px] border-[#a437b2] border-opacity-40 font-medium hover:bg-opacity-10",
  inverse: "bg-white text-blue-600",
  danger: "bg-red-600 text-white",
};

const sizes = {
  sm: "py-2 px-4 text-sm",
  md: "py-2 px-6 text-md",
  lg: "py-3 px-8 text-md",
};

type IconProps =
  | { startIcon: React.ReactElement; endIcon?: never }
  | { endIcon: React.ReactElement; startIcon?: never }
  | { endIcon?: undefined; startIcon?: undefined };

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  isLoading?: boolean;
  ariaLabel?: string;
} & IconProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = "button",
      className = "",
      variant = "primary",
      size = "md",
      isLoading = false,
      startIcon,
      endIcon,
      ariaLabel = "button",
      ...props
    },
    ref
  ) => {
    const { theme } = useTheme();
    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          "flex justify-center items-center w-full md:w-1/3 mx-auto font-light whitespace-nowrap tracking-wide disabled:opacity-70 disabled:bg-gray-600/50 rounded-full shadow-sm focus:outline-none transition-all m-auto my-2",
          variants[variant],
          sizes[size],
          className
        )}
        aria-label={ariaLabel}
        {...props}
        style={{
          backgroundColor:
            variant === "primary" && theme.secondary ? theme.secondary : "",
          color:
            variant === "secondary" && theme.secondary ? theme.secondary : "",
          borderColor:
            variant === "secondary" && theme.secondary ? theme.secondary : "",
        }}
      >
        {isLoading && <Spinner size="sm" className="text-current" />}
        {!isLoading && startIcon}
        <span className="mx-2">{props.children}</span> {!isLoading && endIcon}
      </button>
    );
  }
);

Button.displayName = "Button";
