import { useMutation } from "@tanstack/react-query"
import { MutationConfig } from '@/api/react-query';
import { toast } from 'react-toastify'
import axios from 'axios';
import apiUrl from '@/config'

type Feedback = {
    feedback_qualifications: boolean | null;
    feedback_company: boolean | null;
    feedback_salary: boolean | null;
    feedback_not_interesting: boolean | null;
    feedback_time_slots: boolean | null;
    feedback_other: boolean | null;
    feedback_details: string | null;
}

type SubmitFeedbackProps = {
    feedback: Feedback;
    uuid: string;
}

export const submitFeedback = ({ feedback, uuid }: SubmitFeedbackProps) => {
    return axios.patch(`${apiUrl}/candidates/${uuid}`, {
        candidate_application: {...feedback}
    })
    .catch(error => {
        console.log(error.response?.data)
        return error.response?.data
    })
}

type UseSubmitFeedbackOptions = {
    config?: MutationConfig<typeof submitFeedback>;
}

export const useSubmitFeedback = ({ config }: UseSubmitFeedbackOptions) => {
    const mutation = useMutation({
        onError: (error) => {
            if (error instanceof Error) {
                console.log(error)
                toast.error(error.message)
            } else {
                console.log(error)
                toast.error("An unexpected error occurred")
            }
        },
        ...config,
        mutationFn: submitFeedback
    })

    return { ...mutation, isLoading: mutation.isLoading, isSuccess: mutation.isSuccess }
}