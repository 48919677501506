import { Button } from "@/components/Elements/Button";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { ErrorPage } from "@/components/Layout/Error";
import { Head } from "@/components/Head";
import { MainLayout } from "@/components/Layout/MainLayout";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { PhoneIcon } from "@/components/Icons";
import { Spinner } from "@/components/Elements/Spinner";
import { Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { useTheme } from "@/contexts";
import { useDataFromApi } from "@/api/auth-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSubmitPhone } from "./api/submitPhone";

export const ChangePhone = () => {
  const [phone, setPhone] = useState("");
  const { isDarkMode } = useTheme();
  const { uuid } = useParams();
  const { application, vacancy } = useDataFromApi();

  const { mutateAsync, isLoading } = useSubmitPhone({});
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      vacancy?.data?.vacancy?.public_status! === "closed" &&
      application?.data?.status !== "status_conversation"
    ) {
      navigate(`/prospect/${uuid}/job-closed`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy]);

  const handleOnChange = (value: any, __: any) => {
    setPhone(value);
  };

  const handleSubmit = () => {
    const updatePhone = async () => {
      if (uuid && phone) {
        try {
          await mutateAsync({ phone, uuid });
          navigate(`/prospect/${uuid}/confirm`);
        } catch (error) {
          if (error instanceof Error) {
            console.error("Error updating phone:", error);
            toast.error(error.message);
          } else {
            console.error("Error updating phone:", error);
            toast.error("An unexpected error occured");
          }
        }
      }
    };

    updatePhone();
  };

  if (application?.error?.message) {
    const errorMessage = application.error.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={application?.error.message} />;
  }

  if (vacancy) {
    return (
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
      >
        <MainLayout>
          <Head title="Change phone" />
          <div className="flex flex-col">
            <ContentLayout transparent>
              <div className="grow flex flex-col justify-center w-full md:w-2/3">
                <div className="text-white flex flex-col items-center justify-between">
                  <PhoneIcon />
                  <h3 className="font-[tiemposheadline-bold] mt-3">
                    Phone number
                  </h3>
                </div>
                <div className="text-white text-center">
                  <p>Please provide your phone number.</p>
                </div>
                <div className="w-full sm:2/3 lg:w-1/2 2xl:w-3/4 my-20 mx-auto">
                  <MuiTelInput
                    onChange={handleOnChange}
                    defaultCountry="US"
                    focusOnSelectCountry
                    sx={{
                      width: "100%",
                      backgroundColor: isDarkMode ? "#1E293B" : "white",
                      color: isDarkMode ? "white" : "black",
                      borderRadius: "50px",
                      outline: "none",
                      border: "none",
                      "& .MuiInputBase-input": {
                        color: isDarkMode ? "white" : "black",
                        padding: "0.8rem 0",
                        outline: "none",
                      },
                      "& .MuiInputBase-root": {
                        borderRadius: "50px",
                      },
                      "& .MuiPaper-root": {
                        backgroundColor: isDarkMode
                          ? "#1E293B !important"
                          : "white",
                      },
                    }}
                    value={phone}
                  />
                </div>
              </div>
              <div className="w-full flex flex-col items-center mb-2 md:mb-6">
                <Button
                  onClick={handleSubmit}
                  variant={isDarkMode ? "primaryDark" : "primary"}
                  className="text-white mb-3"
                  size="lg"
                  disabled={!matchIsValidTel(phone)}
                  isLoading={isLoading}
                >
                  Save number
                </Button>
                {window.location.href.includes("/confirm/change-phone") && (
                  <Button
                    onClick={() => navigate(`/prospect/${uuid}/confirm`)}
                    variant={isDarkMode ? "secondaryDark" : "secondary"}
                    className="text-white"
                    size="lg"
                  >
                    Go back
                  </Button>
                )}
              </div>
            </ContentLayout>
          </div>
        </MainLayout>
      </Transition>
    );
  }

  return (
    <MainLayout>
      <Head title="Change phone" />
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-screen h-screen pb-40 flex justify-center items-center">
          <Spinner size="lg" data-testid="loading" />
        </div>
      </Transition>
    </MainLayout>
  );
};
