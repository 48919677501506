import { useQuery } from '@tanstack/react-query'
import { ExtractFnReturnType, QueryConfig } from './react-query';
import axios from 'axios'
import apiUrl from '@/config'

export const getCompany = async ({ companyId }: { companyId?: string }): Promise<any | undefined> => {
    if (!companyId) {
        return undefined
    }
    return await axios.get(`${apiUrl}/employers/${companyId}/details`)
    .catch(error => {
        return { error }
    })
}

type QueryFnType = typeof getCompany

type UseCompanyOptions = {
    companyId?: string | undefined,
    config?: QueryConfig<QueryFnType>
}

export const useCompany = ({ companyId, config }: UseCompanyOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['company', companyId],
        queryFn: () => getCompany({ companyId }),
        onError: (error) => {
            console.log(error)
            throw error
        },
        enabled: !!companyId,
        ...config,
    })
}