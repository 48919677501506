import * as React from "react";
import clsx from "clsx";
import { useTheme } from "@/contexts";
import { Navigation } from "./Navigation";
import { useEffect, useState } from "react";

export const MainLayout: React.FC<{
  children: React.ReactNode;
  currentStep?: number;
  className?: string;
}> = ({ children, currentStep, className }) => {
  const { isDarkMode, theme } = useTheme();
  const [color, setColor] = useState("");

  useEffect(() => {
    setColor(
      !isDarkMode && theme.primary
        ? theme.primary
        : isDarkMode
        ? `linear-gradient(to bottom, #0c0f21, #060413)`
        : `linear-gradient(to bottom, #804FEE, #3A2F7C)`
    );
  }, [theme, isDarkMode]);

  return (
    <div
      className={clsx(`flex flex-col grow w-screen h-full`, className)}
      style={{ background: color }}
    >
      <header className="w-2/3 2xl:w-1/2 mx-auto bg-transparent">
        <Navigation step={currentStep} />
      </header>
      <main className="flex grow relative w-full h-full focus:outline-none">
        {children}
      </main>
    </div>
  );
};
