import { ReactNode } from "react";
import { useTheme } from "@/contexts";

export const ContentLayout: React.FC<{
  children: ReactNode;
  transparent?: boolean;
}> = ({ children, transparent }) => {
  const { isDarkMode } = useTheme();

  return (
    <div
      className={`flex flex-col grow w-screen py-4 font-light ${
        isDarkMode ? "bg-gray-900 text-white" : "bg-white"
      } ${transparent && "!bg-transparent"}`}
    >
      <div className="flex flex-col grow items-center w-full h-full 2xl:w-1/2 m-auto px-6">
        {children}
      </div>
    </div>
  );
};
