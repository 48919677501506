import { Button } from "@/components/Elements/Button";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { ErrorPage } from "@/components/Layout/Error";
import { generateCompensation } from "@/utils/generateCompensation";
import { Head } from "@/components/Head";
import { Hero } from "@/components/Layout/Hero";
import { MailIcon } from "@/components/Icons";
import { MainLayout } from "@/components/Layout/MainLayout";
import { Recruiter } from "@/components/Elements/Recruiter";
import { Spinner } from "@/components/Elements/Spinner";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";
import { useTheme } from "@/contexts";
import { useDataFromApi } from "@/api/auth-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";

export const WhatHappensNext = () => {
  const { isDarkMode } = useTheme();
  const { uuid } = useParams();

  const { bookedInterviews, recruiter, vacancy, company, application } =
    useDataFromApi();

  const [sortedInterviews, setSortedInterviews] = useState<any[]>([]);
  const [displayDates, setDisplayDates] = useState<any>(null);

  const navigate = useNavigate();

  const generateVacancyType = (vacancy_type: string) => {
    let type;
    if (vacancy_type) {
      if (vacancy_type === "zero_hours") {
        type = "Zero hours";
      } else if (vacancy_type === "part_time") {
        type = "Part-time";
      } else if (vacancy_type === "temp") {
        type = "Temporary";
      } else {
        type = vacancy_type;
      }

      return type?.charAt(0).toUpperCase() + type?.slice(1);
    }
  };

  const generateArrangement = (arrangement: string) => {
    if (arrangement) {
      return arrangement?.charAt(0).toUpperCase() + arrangement?.slice(1);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      vacancy?.data?.vacancy?.public_status! === "closed" &&
      application?.data?.status !== "status_conversation"
    ) {
      navigate(`/prospect/${uuid}/job-closed`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy]);

  useEffect(() => {
    const sorted =
      bookedInterviews &&
      !!bookedInterviews?.data?.length &&
      bookedInterviews?.data?.sort((a: any, b: any) => {
        if (
          a.candidate_interview_slot.preferred &&
          !b.candidate_interview_slot.preferred
        )
          return -1;
        else if (
          !a.candidate_interview_slot.preferred &&
          b.candidate_interview_slot.preferred
        )
          return 1;
        else {
          return (
            moment(
              a.candidate_interview_slot.interview_slot.start_time
            ).valueOf() -
            moment(
              b.candidate_interview_slot.interview_slot.start_time
            ).valueOf()
          );
        }
      });
    setSortedInterviews(sorted);
  }, [bookedInterviews]);

  useEffect(() => {
    const displayTimeSlots = () => {
      if (application) {
        if (application?.data.applying_by === "scheduling_conversation") {
          if (sortedInterviews && sortedInterviews.length) {
            const interviewElements = sortedInterviews?.map(
              (date: any, index: number) => {
                return (
                  <div key={index} className="my-1 mb-4 text-sm">
                    <p className="p-0 m-0">
                      {moment(
                        date.candidate_interview_slot.interview_slot.start_time
                      ).format("dddd D MMMM")}{" "}
                      {date.candidate_interview_slot.preferred && (
                        <>
                          {" • "}
                          <span
                            className={clsx("text-indigo-500 font-medium", {
                              "text-indigo-300": isDarkMode,
                            })}
                          >
                            Preferred
                          </span>
                        </>
                      )}
                    </p>
                    <p className="p-0 m-0 text-base font-medium">
                      {moment(
                        date.candidate_interview_slot.interview_slot.start_time
                      ).format("h:mm A")}
                      {" - "}
                      {moment(
                        date.candidate_interview_slot.interview_slot.start_time
                      )
                        .add(
                          date.candidate_interview_slot.interview_slot.duration,
                          "minutes"
                        )
                        .format("h:mm A Z")}
                    </p>
                  </div>
                );
              }
            );
            return (
              <>
                <label className="font-medium text-[#807f8a] text-sm">
                  Your scheduled time slots
                </label>
                {interviewElements}
              </>
            );
          }
        } else if (application?.data.applying_by === "call_back") {
          return (
            <>
              <label className="font-medium text-[#807f8a] text-sm">
                Your scheduled time slots
              </label>
              <div className="my-1 mb-4 text-sm">
                <p className="p-0 m-0">
                  {application.data.candidate_ending_info.call_me_back_days}
                </p>
                <p className="p-0 m-0 text-base font-medium">
                  {application.data.candidate_ending_info.call_me_back_times}
                </p>
              </div>
            </>
          );
        } else if (application.data.applying_by === "ats_link") {
          return (
            <>
              <label className="font-medium text-[#807f8a] text-sm">
                You are applying externally using this link
              </label>
              <div className="my-1 mb-4">
                <p className="p-0 m-0 font-semibold">
                  {vacancy?.data.vacancy.ats_url}
                </p>
              </div>
            </>
          );
        }
        return null;
      }
    };

    setDisplayDates(displayTimeSlots());
  }, [application, vacancy, sortedInterviews, isDarkMode]);

  if (application?.error?.message) {
    const errorMessage = application.error.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={application?.error.message} />;
  }

  if (bookedInterviews && recruiter && vacancy && company && application) {
    return (
      <Transition
        show={true}
        appear={true}
        enter="transition-opacity ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
      >
        <MainLayout>
          <Head title="What happens next" />
          <div className="flex flex-col">
            <Hero icon={<MailIcon />} title="What happens next" />
            <ContentLayout>
              <div>
                <div className="flex flex-col md:flex-row-reverse text-center w-full h-max mx-auto my-4">
                  <div
                    className={clsx(
                      "bg-slate-50/100 text-black p-6 rounded-md w-full h-full mx-auto",
                      {
                        "!bg-slate-700/50 text-white": isDarkMode,
                      }
                    )}
                  >
                    <div>
                      <div className="text-left mb-4 w-full">
                        <label className="font-medium text-[#807f8a] text-sm">
                          Job role
                        </label>
                        <p className="my-1 font-medium">
                          {vacancy?.data?.vacancy?.title}
                        </p>
                      </div>
                      <div className="text-left mb-4 w-full">
                        <label className="font-medium text-[#807f8a] text-sm">
                          Company
                        </label>
                        <p className="my-1 font-medium">
                          {company?.data?.employer?.name}
                        </p>
                      </div>
                      <div className="text-left mb-4 w-full">
                        <label className="font-medium text-[#807f8a] text-sm">
                          Location
                        </label>
                        <p className="my-1 font-medium">
                          {vacancy?.data?.vacancy?.location}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="text-left mb-4 w-full">
                        <label className="font-medium text-[#807f8a] text-sm">
                          Type
                        </label>
                        <p className="my-1 font-medium">
                          {generateVacancyType(
                            vacancy?.data?.vacancy?.vacancy_type
                          )}
                        </p>
                      </div>
                      {vacancy?.data?.vacancy?.arrangement && (
                        <div className="text-left mb-4 w-full">
                          <label className="font-medium text-[#807f8a] text-sm">
                            Arrangement
                          </label>
                          <p className="my-1 font-medium">
                            {generateArrangement(
                              vacancy?.data?.vacancy?.arrangement
                            )}
                          </p>
                        </div>
                      )}
                      <div className="text-left mb-4 w-full">
                        <label className="font-medium text-[#807f8a] text-sm">
                          Salary
                        </label>
                        <p className="my-1 font-medium">
                          {generateCompensation(vacancy?.data?.vacancy!)}
                        </p>
                      </div>
                    </div>
                    <div className="flex text-left mb-4">
                      <div>
                        <label className="font-medium text-[#807f8a] text-sm">
                          Your phone number
                        </label>
                        <p className="my-1 font-medium">
                          {application?.data?.phone
                            ? application?.data?.phone
                            : "Not found"}
                        </p>
                      </div>
                      <button
                        onClick={() =>
                          navigate(`/prospect/${uuid}/confirm/change-phone`)
                        }
                        className={clsx(
                          "text-indigo-500 text-xs font-medium border border-indigo-500 rounded-full px-6 py-1.5 my-4 mx-2 hover:bg-indigo-300/5 transition",
                          { "!text-indigo-400": isDarkMode }
                        )}
                      >
                        Change
                      </button>
                    </div>
                    <div className="flex text-left mb-4">
                      <div>
                        <label className="font-medium text-[#807f8a] text-sm">
                          Your email
                        </label>
                        <p className="my-1 font-medium">
                          {application?.data?.email
                            ? application?.data?.email
                            : "Not found"}
                        </p>
                      </div>
                      <button
                        onClick={() =>
                          navigate(`/prospect/${uuid}/confirm/change-email`)
                        }
                        className={clsx(
                          "text-indigo-500 text-xs font-medium border border-indigo-500 rounded-full px-6 py-1.5 my-4 mx-2 hover:bg-indigo-300/5 transition",
                          { "!text-indigo-400": isDarkMode }
                        )}
                      >
                        Change
                      </button>
                    </div>
                    <div className="text-left mb-4">{displayDates}</div>
                  </div>
                  <div className="flex flex-col mx-auto md:mr-12 mt-8 md:mt-0 w-full text-center md:text-left">
                    {application?.data.applying_by === "call_back" ? (
                      <div>
                        <p>
                          Thank you for confirming your availability. Your
                          recruiter is scheduled to contact you within the time
                          frame you've selected, ensuring a convenient and
                          efficient discussion.
                        </p>
                        <p>
                          To enhance your experience and establish a more
                          personal connection, we encourage you to connect with
                          your recruiter on LinkedIn. This can provide valuable
                          insights and further personalize your recruitment
                          journey.
                        </p>
                        <p className="mb-10">
                          As an additional, optional step, we invite you to send
                          a direct message to your recruiter. Whether it's to
                          express your enthusiasm for the role, ask any
                          preliminary questions, or provide further information
                          about your qualifications, this message can set a
                          positive tone for your upcoming interaction.
                        </p>
                      </div>
                    ) : application?.data.applying_by === "ats_link" ? (
                      <div>
                        <p>
                          Your application will now proceed on the external
                          company website. Please be assured that all subsequent
                          steps in the application process will be seamlessly
                          managed by the employer directly.
                        </p>
                        <p>
                          There's nothing more you need to do on our platform at
                          this stage. We wish you the best in this application
                          and hope it leads to a fulfilling career path.
                        </p>
                        <Button
                          onClick={() =>
                            (window.location.href =
                              vacancy?.data?.vacancy?.ats_url)
                          }
                          className="mt-10 !w-fit"
                        >
                          Continue application
                        </Button>
                      </div>
                    ) : (
                      <div className="w-full mx-auto">
                        <p>
                          Thank you, we’ve sent a confirmation email with
                          details about your conversation with the recruiter.
                        </p>
                        <p>
                          Keep in mind that this is only provisional and the
                          call will have to be confirmed by the recruiter before
                          it takes place.
                        </p>
                        <p>
                          We will send you a reminder before your call is due.
                          In the meantime, feel free to connect with your
                          recruiter on LinkedIn.
                        </p>
                        <p className="mb-10">
                          As a final optional step, you can write a message to
                          the recruiter if you wish.
                        </p>
                      </div>
                    )}

                    {application?.data.applying_by !== "ats_link" && (
                      <>
                        <div>
                          {recruiter?.data && (
                            <Recruiter
                              name={recruiter.data.recruiter.name}
                              avatar={recruiter.data.recruiter.avatar_url!}
                              jobTitle={recruiter.data.recruiter.job_title}
                              message={recruiter.data.recruiter.message}
                              linkedIn={recruiter.data.recruiter.linkedin_url!}
                            />
                          )}
                          <div className="w-full lg:w-2/3 mx-auto hidden md:flex flex-col items-center mt-10">
                            <Button
                              onClick={() =>
                                navigate(`/prospect/${uuid}/message`)
                              }
                              variant={
                                isDarkMode ? "secondaryDark" : "secondary"
                              }
                              className={clsx(
                                "text-[#c13fbb] mb-6 !w-full !sm:w-2/3",
                                {
                                  "text-slate-50": isDarkMode,
                                }
                              )}
                              size="lg"
                            >
                              Write message to recruiter
                            </Button>
                          </div>
                        </div>
                        <div className="w-full flex md:hidden flex-col items-center">
                          <Button
                            onClick={() =>
                              navigate(`/prospect/${uuid}/message`)
                            }
                            variant={isDarkMode ? "secondaryDark" : "secondary"}
                            className={clsx(
                              "text-[#c13fbb] mb-6 !w-full !sm:w-2/3",
                              {
                                "text-slate-50": isDarkMode,
                              }
                            )}
                            size="lg"
                          >
                            Write message to recruiter
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </ContentLayout>
          </div>
        </MainLayout>
      </Transition>
    );
  }

  return (
    <MainLayout>
      <Head title="What happens next" />
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-screen h-screen pb-40 flex justify-center items-center">
          <Spinner size="lg" />
        </div>
      </Transition>
    </MainLayout>
  );
};
