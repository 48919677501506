import { useTheme } from "@/contexts";
import clsx from "clsx";

type Props = {
  label: any;
  value: any;
  checked: boolean;
  disabled?: boolean;
  onClick?: () => void;
  ariaLabel?: string;
  className?: string;
};

export const RadioButton = ({
  label,
  value,
  checked,
  disabled,
  onClick,
  ariaLabel,
  className,
}: Props) => {
  const { isDarkMode } = useTheme();

  const labelClassNames = clsx(
    "inline-flex items-center justify-center w-16 px-4 py-2 border rounded-full shadow-sm font-medium cursor-pointer transition-all",
    {
      "border-gray-300 text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500":
        !checked && !isDarkMode,
      "bg-indigo-100": checked && !isDarkMode,
      "bg-transparent hover:bg-indigo-400/5 text-gray-50 border-gray-100/40":
        !checked && isDarkMode,
      "bg-[#e9ff5b]/20": checked && isDarkMode,
      "bg-gray-100 text-opacity-40 cursor-default": disabled && !isDarkMode,
      "bg-slate-100": disabled && isDarkMode,
    },
    className
  );

  return (
    <label className={labelClassNames}>
      <input
        type="radio"
        className="sr-only"
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onClick}
        aria-label={ariaLabel}
      />
      {label}
    </label>
  );
};
