import { Button } from "@/components/Elements/Button";
import { CheckIcon } from "@/components/Icons";
import { Head } from "@/components/Head";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { ErrorPage } from "@/components/Layout/Error";
import { MainLayout } from "@/components/Layout/MainLayout";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { Payload } from "./types";
import { RadioButton } from "@/components/Elements/RadioButton";
import { Spinner } from "@/components/Elements/Spinner";
import { ThumbsUpIcon, ThumbsDownIcon } from "@/components/Icons";
import { Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { useTheme } from "@/contexts";
import { useDataFromApi } from "@/api/auth-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useSubmitPayload } from "./api/submitPayload";
import moment from "moment";
import validator from "validator";

export const ConfirmPhoneEmail = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { uuid } = useParams();
  const { isDarkMode } = useTheme();
  const { application, company, vacancy } = useDataFromApi();
  const { mutateAsync } = useSubmitPayload({});
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailIsCorrect, setEmailIsCorrect] = useState<boolean | null>(null);
  const [emailConsent, setEmailConsent] = useState(false);
  const [GDPRConsent, setGDPRConsent] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (application) {
      if (application?.data?.status === "status_lead") {
        navigate(`/prospect/${uuid}/job-description`);
      }
      if (!application.data?.email) {
        setEmailIsCorrect(false);
      }
    }
  }, [application, uuid, navigate]);

  useEffect(() => {
    if (vacancy?.data?.vacancy?.public_status! === "closed") {
      navigate(`/prospect/${uuid}/job-closed`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy]);

  const handleOnChange = (value: any, __: any) => {
    setPhone(value);
  };

  if (application?.error?.message) {
    const errorMessage = application.error.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={application?.error.message} />;
  }

  const handleSubmit = () => {
    const timestamp = moment().utc().format("YYYY-MM-DD HH:mm:ss.SSSSSS Z");

    const updatePhoneEmail = async () => {
      if (!uuid) {
        return;
      }

      try {
        const payload: Payload = { uuid, emailConsent, GDPRConsent, timestamp };
        const requiresGdprConsent =
          company?.data?.employer?.require_gdpr_consent;

        if (phone) {
          payload.phone = phone;

          if (emailIsCorrect) {
            if (requiresGdprConsent) {
              payload.timestamp = timestamp;
            }
          } else if (email) {
            payload.email = email;
            if (requiresGdprConsent) {
              payload.timestamp = timestamp;
            }
          }
        }

        if (Object.keys(payload).length > 1) {
          setLoading(true);
          await mutateAsync(payload, {
            onSuccess: async () => {
              await queryClient.refetchQueries(["application"]).then(() => {
                const updatedApplication = queryClient.getQueryData([
                  "application",
                  uuid,
                ]) as any;
                if (
                  updatedApplication?.data?.phone.length &&
                  updatedApplication?.data?.phone.length
                ) {
                  navigate(`/prospect/${uuid}/select-dates`);
                }
              });
            },
          }).finally(() => {
            setLoading(false);
          });
        }
      } catch (error) {
        setLoading(false);
        if (error instanceof Error) {
          console.error("Error updating data:", error);
          toast.error(error.message);
        } else {
          console.error("Error updating data:", error);
          toast.error("An unexpected error occured");
        }
      }
    };

    updatePhoneEmail();
  };

  const isButtonDisabled = () => {
    if (matchIsValidTel(phone) && emailIsCorrect === true) {
      if (company?.data?.employer?.require_gdpr_consent!) {
        return !GDPRConsent;
      } else {
        return false;
      }
    } else if (
      matchIsValidTel(phone) &&
      emailIsCorrect === false &&
      validator.isEmail(email)
    ) {
      if (company?.data?.employer?.require_gdpr_consent!) {
        return !GDPRConsent;
      } else {
        return false;
      }
    } else return true;
  };

  if (application && company && vacancy) {
    return (
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
      >
        <MainLayout currentStep={6}>
          <Head title="You're a match" />
          <div className="flex flex-col md:my-auto md:pb-10">
            <ContentLayout transparent>
              <div className="grow md:grow-0 flex flex-col justify-center text-white text-center -mt-30 md:mt-0 mb-10">
                <div className="flex flex-col items-center justify-between">
                  <CheckIcon />
                  <h3 className="xxs:mt-6 xs:mt-3 mb-0 font-[tiemposheadline-bold]">
                    Confirm Preferred Phone and Email
                  </h3>
                </div>
                <div className="text-center w-full md:w-2/3 lg:w-1/2 mx-auto">
                  <div className="xxs:p-0 xs:py-4">
                    <p>
                      We'd love you to book a call with the recruiter to learn
                      more about this role. Please confirm your phone number and
                      email below for the recruiter to call you at a scheduled
                      time.
                    </p>
                    <div className="w-full sm:w-2/3 md:w-3/4 2xl:w-full text-left mx-auto pt-6 pb-4">
                      <label className="text-gray-200 text-sm font-medium pl-4">
                        Phone
                      </label>
                      <MuiTelInput
                        onChange={handleOnChange}
                        defaultCountry="US"
                        focusOnSelectCountry
                        sx={{
                          width: "100%",
                          backgroundColor: isDarkMode ? "#1E293B" : "white",
                          color: isDarkMode ? "white" : "black",
                          borderRadius: "50px",
                          outline: "none",
                          border: "none",
                          marginTop: "0.2rem",
                          "& .MuiInputBase-input": {
                            color: isDarkMode ? "white" : "black",
                            padding: "0.7rem 0",
                            outline: "none",
                          },
                          "& .MuiInputBase-root": {
                            borderRadius: "50px",
                          },
                          "& .MuiPaper-root": {
                            backgroundColor: isDarkMode
                              ? "#1E293B !important"
                              : "white",
                          },
                        }}
                        value={phone}
                      />
                    </div>
                    {application?.data?.email ? (
                      <div className="my-8">
                        <p className="text-2xl">
                          Is this your correct email address?
                        </p>
                        <p className="text-lg font-semibold my-6">
                          {application?.data?.email}
                        </p>
                        <div className="flex justify-center gap-4 mt-8">
                          <RadioButton
                            label={
                              <ThumbsUpIcon
                                className={
                                  emailIsCorrect
                                    ? isDarkMode
                                      ? "text-white"
                                      : "text-black"
                                    : "text-white"
                                }
                              />
                            }
                            value="Yes"
                            checked={emailIsCorrect ? true : false}
                            onClick={() => setEmailIsCorrect(true)}
                          />
                          <RadioButton
                            label={
                              <ThumbsDownIcon
                                className={
                                  emailIsCorrect === false
                                    ? isDarkMode
                                      ? "text-white"
                                      : "text-black"
                                    : "text-white"
                                }
                              />
                            }
                            value="No"
                            checked={emailIsCorrect === false ? true : false}
                            onClick={() => setEmailIsCorrect(false)}
                          />
                        </div>
                        <Transition
                          show={emailIsCorrect === false ? true : false}
                          enter="transition-all duration-500 transform"
                          enterFrom="opacity-0 translate-y-4"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition-all duration-0 transform"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-4"
                        >
                          <div className="flex flex-col justify-center w-full sm:2/3 md:w-3/4 2xl:w-full my-6 mx-auto text-left pb-8">
                            <label className="text-gray-200 text-sm font-medium pl-4">
                              Email
                            </label>
                            <input
                              type="email"
                              required
                              placeholder="Enter your email address"
                              className={`w-full rounded-full px-5 py-2.5 mt-1 text-black ${
                                isDarkMode && "bg-[#1E293B] text-white"
                              }`}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </Transition>
                      </div>
                    ) : (
                      <div className="w-full sm:2/3 md:w-3/4 2xl:w-full my-4 mx-auto text-left pb-8">
                        <label className="text-gray-200 text-sm font-medium pl-4">
                          Email
                        </label>
                        <input
                          type="email"
                          required
                          placeholder="Enter your email address"
                          className={`w-full rounded-full px-5 py-2.5 mt-1 text-black ${
                            isDarkMode && "bg-[#1E293B] text-white"
                          }`}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    )}

                    <p className="mt-10">
                      Click below to select dates and times that work for you.
                    </p>
                  </div>
                </div>
              </div>
              {company?.data?.employer?.require_gdpr_consent! && (
                <div className="text-white flex justify-center items-start gap-2 sm:gap-3 mb-10 w-full sm:w-3/4 md:w-1/2 lg:w-1/2">
                  <input
                    type="checkbox"
                    name="gdpr-consent"
                    id="gdpr-consent"
                    className="cursor-pointer w-10 h-10 sm:w-8 sm:h-8 -mt-1 sm:mt-0"
                    onChange={() => setGDPRConsent(!GDPRConsent)}
                  />
                  <label htmlFor="gdpr-consent" className="cursor-pointer">
                    We need your consent to go forward for GDPR purposes. You
                    can opt-out at any time.{" "}
                    <a
                      href="https://www.cielotalent.com/privacy-policy/"
                      rel="noreferrer"
                      target="_blank"
                      className="underline"
                    >
                      See here
                    </a>
                  </label>
                </div>
              )}
              <div className="text-white flex justify-center items-start gap-2 sm:gap-3 mb-10 w-full sm:w-3/4 md:w-1/2 lg:w-1/2">
                <input
                  type="checkbox"
                  name="email-consent"
                  id="email-consent"
                  className="cursor-pointer w-10 h-10 sm:w-8 sm:h-8 -mt-1 sm:mt-0"
                  onChange={() => setEmailConsent(!emailConsent)}
                />
                <label htmlFor="email-consent" className="cursor-pointer">
                  By checking this box, you give Cielo Digital permission to
                  contact you via email regarding job opportunities.
                </label>
              </div>

              <Button
                onClick={handleSubmit}
                disabled={isButtonDisabled()}
                isLoading={loading}
                variant={isDarkMode ? "primaryDark" : "primary"}
                className="mb-6"
                size="lg"
              >
                Book call
              </Button>
            </ContentLayout>
          </div>
        </MainLayout>
      </Transition>
    );
  }

  return (
    <MainLayout>
      <Head title="You're a match" />
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full h-screen pb-40 flex justify-center items-center">
          <Spinner size="lg" data-testid="loading" />
        </div>
      </Transition>
    </MainLayout>
  );
};
