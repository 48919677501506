import React from "react";
import { useTheme } from "@/contexts";
import { SkillTags } from "../SkillTags";

type MatchedSkillsProps = {
  skills: any[];
};

export const MatchedSkills: React.FC<MatchedSkillsProps> = ({ skills }) => {
  const { isDarkMode } = useTheme();

  return (
    <div className="w-full md:w-2/3 mx-auto text-center">
      <h2 className="font-[tiemposheadline-bold] whitespace-nowrap">
        How you've matched
      </h2>
      <p>
        Your online profile matched you with the following key skills for this
        role.
      </p>
      <SkillTags skills={skills} isDarkMode={isDarkMode} />
    </div>
  );
};
