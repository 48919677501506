import { useMutation } from "@tanstack/react-query"
import { MutationConfig } from '@/api/react-query';
import axios from 'axios';
import apiUrl from '@/config'

type SubmitInterviewProps = {
    interview_id: number;
    candidate_id: string;
    preferred: boolean;
}

export const submitInterviewSlot = ({ interview_id, candidate_id, preferred }: SubmitInterviewProps) => {
    return axios.post(`${apiUrl}/interview_slots/${interview_id}`, 
    {
        interview_slot: preferred ? {
            candidate_id,
            preferred,
        } : {
            candidate_id,
            backup: true
        }
    })
    .catch(error => {
        console.log(error.response?.data)
        throw error
    })
}

type UseSubmitInterviewSlot = {
    config?: MutationConfig<typeof submitInterviewSlot>;
}

export const useSubmitInterviewSlot = ({ config }: UseSubmitInterviewSlot) => {
    const mutation = useMutation({
        onError: (error) => {
            if (error instanceof Error) {
                console.log(error)
                // toast.error(error.message)
            } else {
                console.log(error)
                // toast.error("An unexpected error occurred")
            }
        },
        ...config,
        mutationFn: submitInterviewSlot
    })

    return { ...mutation, isLoading: mutation.isLoading, isSuccess: mutation.isSuccess, isError: mutation.isError }

}