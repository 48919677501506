import { MailIcon } from "@/components/Icons";
import { Button } from "@/components/Elements/Button";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { ErrorPage } from "@/components/Layout/Error";
import { Head } from "@/components/Head";
import { Hero } from "@/components/Layout/Hero";
import { MainLayout } from "@/components/Layout/MainLayout";
import { Spinner } from "@/components/Elements/Spinner";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";
import { useDataFromApi } from "@/api/auth-query";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTriggerUnsubscribe } from "./api/triggerUnsubscribe";

export const Unsubscribe = () => {
  const { application } = useDataFromApi();
  const { mutateAsync, isSuccess, isLoading } = useTriggerUnsubscribe({});
  const { uuid } = useParams();
  const [queryParameters] = useSearchParams();
  const source = queryParameters.get("source") || "";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (application?.error?.message) {
    const errorMessage = application.error.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={application?.error.message} />;
  }

  const handleUnsubscribe = async () => {
    if (application && uuid && source) {
      await mutateAsync({ uuid, source });
    }
  };

  if (application && uuid && source) {
    return (
      <>
        <Transition
          show={!isSuccess}
          appear
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          className="w-full h-full"
        >
          <MainLayout>
            <Head title="Unsubscribe" description="Cielo TaaS" />
            <div className="flex flex-col">
              <Hero title="Unsubscribe" icon={<MailIcon />} />
              <ContentLayout>
                <div className="flex flex-col items-center text-center mx-auto pb-12 pt-6 w-full md:w-2/3">
                  <p>
                    If you are considering no longer receiving our email
                    communications, we want to offer you an easy and
                    straightforward way to unsubscribe. We understand that email
                    preferences can change, and it's important to us that we
                    respect your inbox.
                  </p>
                  <p>
                    By clicking the button below, you will be able to
                    immediately and effectively remove yourself from our mailing
                    list. We aim to ensure this process is as hassle-free as
                    possible, so you can have full control over the types of
                    communications you receive.
                  </p>
                  <p>
                    Should you choose to unsubscribe now, we want to thank you
                    for your past engagement.
                  </p>
                  <Button
                    className="my-8"
                    size="lg"
                    isLoading={isLoading}
                    onClick={() => handleUnsubscribe()}
                  >
                    Unsubscribe from emails
                  </Button>
                </div>
              </ContentLayout>
            </div>
          </MainLayout>
        </Transition>
        <Transition
          show={isSuccess}
          appear
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          className="w-full h-full"
        >
          <MainLayout>
            <Head title="Unsubscribe" description="Cielo TaaS" />
            <div className="flex flex-col">
              <Hero title="Unsubscribe" icon={<MailIcon />} />
              <ContentLayout>
                <div className="flex flex-col items-center text-center mx-auto pb-12 pt-4 w-full md:w-2/3">
                  <h3>You have unsubscribed from our email messages</h3>
                  <p>
                    Thank you for unsubscribing from our email messages. We
                    understand that your inbox can get overwhelming at times,
                    and we respect your decision to manage your email
                    subscriptions.
                  </p>
                  <p>
                    However, if you have unsubscribed by mistake, please{" "}
                    <a
                      className="text-indigo-600"
                      href="mailto:email@cielo-digital.com"
                    >
                      email us
                    </a>
                    .
                  </p>
                </div>
              </ContentLayout>
            </div>
          </MainLayout>
        </Transition>
      </>
    );
  }

  return (
    <MainLayout>
      <Head title="Job Description" />
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-screen h-screen pb-40 flex justify-center items-center">
          <Spinner size="lg" />
        </div>
      </Transition>
    </MainLayout>
  );
};
