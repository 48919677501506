import { useMutation } from "@tanstack/react-query"
import { MutationConfig } from '@/api/react-query';
import { toast } from "react-toastify"
import axios from 'axios';
import apiUrl from '@/config'

type SubmitPhoneProps = {
    phone?: string;
    email?: string;
    uuid: string | null;
}

export const submitPhone = ({ phone, email, uuid }: SubmitPhoneProps) => {
    return axios.patch(`${apiUrl}/candidates/${uuid}`, {
        candidate_application: {
            phone,
            email
        }
    }).catch((error) => {
        return { error }
    })
}

type UseSubmitPhoneOptions = {
    config?: MutationConfig<typeof submitPhone>;
}

export const useSubmitPhone = ({ config }: UseSubmitPhoneOptions) => {
    const mutation = useMutation({
        onError: (error) => {
            if (error instanceof Error) {
                console.log(error)
                toast.error(error.message)
            } else {
                console.log(error)
                toast.error("An unexpected error occurred")
            }
        },
        ...config,
        mutationFn: submitPhone
    })

    return { ...mutation, isLoading: mutation.isLoading }
}