import { useMutation } from "@tanstack/react-query"
import { MutationConfig } from '@/api/react-query';
import { toast } from 'react-toastify'
import axios from 'axios';
import apiUrl from '@/config'

type UnwithdrawProps = {
    uuid: string;
    vacancy_id: string;
}

export const unwithdraw = ({ uuid, vacancy_id }: UnwithdrawProps) => {
    return axios.post(`${apiUrl}/vacancies/${vacancy_id}/candidates/${uuid}/unwithdraw`)
    .catch(error => {
        console.log(error.response?.data)
        return error.response?.data
    })
}

type UseUnwithdrawOptions = {
    config?: MutationConfig<typeof unwithdraw>;
}

export const useUnwithdraw = ({ config }: UseUnwithdrawOptions) => {
    const mutation = useMutation({
        onError: (error) => {
            if (error instanceof Error) {
                console.log(error)
                toast.error(error.message)
            } else {
                console.log(error)
                toast.error("An unexpected error occurred")
            }
        },
        ...config,
        mutationFn: unwithdraw
    })

    return { ...mutation, isLoading: mutation.isLoading, isSuccess: mutation.isSuccess }
}