import React, { useState, useEffect } from "react";
import { Combobox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { InputTypes } from "../../types";
import { useTheme } from "@/contexts";
import { autofillSkills } from "../../api/autofillSkills";
import clsx from "clsx";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState<string>(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(timer);
  }, [value, delay]);

  return debouncedValue;
};

export const Input = ({ id, query, value, handleSelectSkill }: InputTypes) => {
  const { isDarkMode } = useTheme();
  const [selectedSkill, setSelectedSkill] = useState<any>(value ? value : null);

  const [searchValue, setSearchValue] = useState("");
  const [filteredArray, setFilteredArray] = useState<any[]>([]);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    autofillSkills({ value: debouncedSearchValue }).then((response) => {
      if (response?.data?.length) {
        const capitalizedJobTitles = response?.data?.map((item: any) => {
          const words = item.text.split(" ");
          const capitalizedWords = words.map((word: string) =>
            capitalizeFirstLetter(word)
          );
          return capitalizedWords.join(" ");
        });
        setFilteredArray([...new Set(capitalizedJobTitles)]);
      }
    });
  }, [debouncedSearchValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <Combobox
      as="div"
      value={value ? value : selectedSkill}
      onChange={setSelectedSkill}
      className="relative w-full sm:w-2/3"
    >
      <Combobox.Label className="relative bottom-0.5 text-sm font-medium text-gray-500">
        Skill
      </Combobox.Label>
      <Combobox.Input
        className={clsx(
          "w-full rounded-sm border border-gray-300 bg-white py-3 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 text-sm",
          { "!bg-transparent !text-white !border-slate-600": isDarkMode }
        )}
        onChange={(e) => handleChange(e)}
        autoComplete="off"
        displayValue={() => (value ? value : selectedSkill?.name)}
      />
      <Combobox.Options className="absolute capitalize z-10 m-0 mt-1 py-0 max-h-48 w-full overflow-auto rounded-sm bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        {query && (
          <Combobox.Option
            value={query}
            onClick={(e: any) => {
              handleSelectSkill(id, e.target.innerText);
            }}
            className={({ active, disabled }) =>
              classNames(
                active && isDarkMode
                  ? "bg-indigo-600 text-white"
                  : active && !isDarkMode
                  ? "bg-indigo-600 text-white"
                  : isDarkMode
                  ? "bg-slate-700 text-white"
                  : "text-gray-800",
                "flex px-4 py-3 my-0 text-sm cursor-pointer w-full text-left"
              )
            }
          >
            {query}
          </Combobox.Option>
        )}
        {filteredArray?.map((item, index) => (
          <Combobox.Option
            key={index}
            value={item}
            onClick={(e: any) => {
              handleSelectSkill(id, e.target.innerText);
            }}
            className={({ active, disabled }) =>
              classNames(
                active && isDarkMode
                  ? "bg-indigo-600 text-white"
                  : active && !isDarkMode
                  ? "bg-indigo-600 text-white"
                  : isDarkMode
                  ? "bg-slate-700 text-white"
                  : "text-gray-800",
                "flex px-4 py-3 my-0 text-sm cursor-pointer w-full text-left capitalize"
              )
            }
          >
            {({ active, selected }) => (
              <div className="relative w-full">
                <span
                  className={classNames(
                    "block truncate capitalize",
                    selected && "font-semibold"
                  )}
                >
                  {item}
                </span>

                {selected && (
                  <span
                    className={classNames(
                      "absolute inset-y-0 right-0 flex items-center",
                      active ? "text-white" : "text-indigo-600",
                      isDarkMode && "text-white"
                    )}
                  >
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                )}
              </div>
            )}
          </Combobox.Option>
        ))}
      </Combobox.Options>
    </Combobox>
  );
};
