import { Currencies } from "@/types";

export const convertCurrencyCodeToSymbol = (currencyCode: Currencies) => {
    const currencySymbols = {
      USD: '$',
      EUR: '€',
      GBP: '£',
      JPY: '¥',
      AUD: 'A$',
      CAD: 'C$',
      CHF: 'CHF',
      CNY: '¥',
      DKK: 'kr',
      SEK: 'kr',
      NZD: 'NZ$',
      INR: '₹',
      MXN: 'Mex$',
      BRL: 'R$',
      ZAR: 'R',
      MYR: 'RM',
      RM: 'RM',
      VND: '₫',
      PHP: '₱'
    };
  
    return currencySymbols[currencyCode] || currencyCode;
  }