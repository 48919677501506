import { useMutation } from "@tanstack/react-query"
import { MutationConfig } from '@/api/react-query';
import { toast } from "react-toastify"
import axios from 'axios';
import apiUrl from '@/config'

type SubmitSelectionProps = {
    time_frame: string | null;
    uuid: string | null;
    vacancy_id: string | null;
}

export const submitSelection = ({ time_frame, uuid, vacancy_id }: SubmitSelectionProps) => {
    return axios.patch(`${apiUrl}/vacancies/${vacancy_id}/candidates/${uuid}/apply_call_me_back`, {
        call_me_back: {
            time_frame
        }
    }).catch((error) => {
        return { error }
    })
}

type UseSubmitSelectionOptions = {
    config?: MutationConfig<typeof submitSelection>;
}

export const useSubmitSelection = ({ config }: UseSubmitSelectionOptions) => {
    const mutation = useMutation({
        onError: (error) => {
            if (error instanceof Error) {
                console.log(error)
                toast.error(error.message)
            } else {
                console.log(error)
                toast.error("An unexpected error occurred")
            }
        },
        ...config,
        mutationFn: submitSelection
    })

    return { ...mutation, isLoading: mutation.isLoading }
}