import axios from 'axios';
import apiUrl from '@/config'

type Props = {
    value?: string | undefined;
}

export const autofillSkills = ({ value }: Props) => {
    if (value && !!value.length) {
        return axios.get(`${apiUrl}/autofill/skills/${value}`)
        .catch(error => {
            console.log(error.response?.data)
            return error.response?.data
        })
    }   else {
        return Promise.resolve({})
    }
}