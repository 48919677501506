const env = process.env.REACT_APP_ENV || 'development';
let apiUrl

if (env === 'production') {
    apiUrl = "https://taas1.cielo-digital.com/api/v1"
} else if (env === 'staging') {
    apiUrl = "https://taas1-stage.cielotalentlink.com/api/v1"
} else if (env === 'preview') {
    apiUrl = "https://taas1-preview.cielo-digital.com/api/v1"
} else if (env === 'project-testing') {
    apiUrl = "https://taas1-project1-testing.elysium-ltd.net/api/v1"
} else if (env === 'project2-testing') {
    apiUrl = "https://taas1-project2-testing.elysium-ltd.net/api/v1"
} else {
    apiUrl = "https://taas1-testing.elysium-ltd.net/api/v1"
}

export default apiUrl as string