import { Button } from "@/components/Elements/Button";
import { DarkModeButton } from "@/components/Elements/DarkModeButton";
import { useTheme } from "@/contexts";
import clsx from "clsx";
import React from "react";

export const NotFound = () => {
  const { isDarkMode } = useTheme();
  return (
    <div
      className={clsx(
        "bg-gradient-to-b from-[#804FEE] to-[#372896] min-h-screen h-full flex justify-center items-center px-6",
        { "!from-[#0D2338] !to-[#17132C]": isDarkMode }
      )}
    >
      <div className="mx-auto max-w-max">
        <div className="absolute top-5 right-5">
          <DarkModeButton />
        </div>
        <main className="sm:flex">
          <p className="text-4xl font-bold tracking-tight text-[#f7d449] sm:text-5xl">
            404
          </p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-bold tracking-tight text-gray-50 sm:text-5xl">
                Page not found
              </h1>
              <p className="mt-1 text-base text-gray-200">
                Please check the URL in the address bar and try again.
              </p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Button className="flex rounded-full border border-transparent bg-[#E74BC8] mx-0 px-4 py-2.5 text-sm font-medium text-white shadow-sm">
                Refresh
              </Button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
