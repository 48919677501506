import { useQuery } from '@tanstack/react-query'
import { ExtractFnReturnType, QueryConfig } from '@/api/react-query';
import axios from 'axios'
import apiUrl from '@/config'

export const getFreeInterviews = async ({ uuid }: { uuid?: string }): Promise<any | undefined> => {
    if (uuid) {
        return await axios.get(`${apiUrl}/candidates/${uuid}/interview_slots/free`)
        .catch(error => {
            return { error }
        })
    }
}

type QueryFnType = typeof getFreeInterviews

type UseInterviewsOptions = {
    uuid?: string | null,
    config?: QueryConfig<QueryFnType>
}

export const useFreeInterviews = ({ uuid, config }: UseInterviewsOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['freeInterviews', uuid],
        queryFn: () => uuid && getFreeInterviews({ uuid }),
        onError: (error) => {
            console.log(error)
            throw error
        },
        enabled: !!uuid,
        ...config,
    })
}