import { useQuery } from '@tanstack/react-query'
import { ExtractFnReturnType, QueryConfig } from '@/api/react-query';
import axios from 'axios'
import apiUrl from '@/config'

export const getCandidateSkills = async ({ uuid }: { uuid?: string }): Promise<any | null> => {
    return await axios.get(`${apiUrl}/candidates/${uuid}/candidate_vacancy_skills`)
    .catch(error => {
        return { error }
    })
}

type QueryFnType = typeof getCandidateSkills

type UseJobDescriptionOptions = {
    uuid: string | undefined,
    config?: QueryConfig<QueryFnType>
}

export const useCandidateSkills = ({ uuid, config }: UseJobDescriptionOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['candidateSkills', uuid],
        queryFn: () => getCandidateSkills({ uuid }),
        onError: (error) => {
            console.log(error)
            throw error
        },
        enabled: !!uuid,
        ...config
    })
}