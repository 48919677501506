import { useMutation } from "@tanstack/react-query"
import { MutationConfig } from '@/api/react-query';
import { toast } from "react-toastify"
import { Payload } from "../types";
import axios from 'axios';
import apiUrl from '@/config'

export const submitPayload = ({ phone, email, emailConsent, GDPRConsent, timestamp, uuid }: Payload) => {
    return axios.patch(`${apiUrl}/candidates/${uuid}`, {
        candidate_application: {
            phone,
            email,
            gdpr_consent_given_at: GDPRConsent ? timestamp : null,
            contact_all_jobs_consent_given_at: emailConsent ? timestamp : null
        }
    }).catch((error) => {
        return { error }
    })
}

type UseSubmitPayloadOptions = {
    config?: MutationConfig<typeof submitPayload>;
}

export const useSubmitPayload = ({ config }: UseSubmitPayloadOptions) => {
    const mutation = useMutation({
        onError: (error) => {
            if (error instanceof Error) {
                console.log(error)
                toast.error(error.message)
            } else {
                console.log(error)
                toast.error("An unexpected error occurred")
            }
        },
        ...config,
        mutationFn: submitPayload
    })

    return { ...mutation, isLoading: mutation.isLoading }
}