import { ActionButtons } from "@/components/Elements/ActionButtons";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { ErrorPage } from "@/components/Layout/Error";
import { Head } from "@/components/Head";
import { Hero } from "@/components/Layout/Hero";
import { MainLayout } from "@/components/Layout/MainLayout";
import { Popup } from "@/components/Elements/Popup";
import { Spinner } from "@/components/Elements/Spinner";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";
import { useCompanyProfile } from "./hooks/useCompanyProfile";
import { useTheme } from "@/contexts";
import { useEffect } from "react";
import clsx from "clsx";

export const CompanyProfile = () => {
  const { isDarkMode, theme } = useTheme();

  const {
    vacancyData,
    companyData,
    body,
    isLoading,
    accepted,
    setAccepted,
    handleResponse,
    applicationError,
  } = useCompanyProfile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (applicationError?.message) {
    const errorMessage = applicationError.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={applicationError.message} />;
  }

  if (companyData && vacancyData) {
    return (
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
      >
        <MainLayout currentStep={2}>
          <Head title={vacancyData.name} />
          <div className="flex flex-col">
            <Hero
              showLogo
              logo={companyData.logo_url}
              title={companyData.name}
              step={2}
            />
            <ContentLayout>
              <div className="grow w-full md:w-2/3">
                <div
                  className="mt-8 mb-12"
                  dangerouslySetInnerHTML={{
                    __html: body!,
                  }}
                />
                <div className="flex flex-col self-start mb-20">
                  <div
                    className={clsx("w-20 h-1 mb-6 bg-[#6E49C2]", {
                      "!bg-[#1EA0FF]": isDarkMode,
                    })}
                    style={{
                      background: theme.secondary ? theme.secondary : "",
                    }}
                  />
                  {vacancyData.vacancy_url && (
                    <a
                      href={vacancyData.vacancy_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx("text-[#6E49C2] font-semibold w-fit", {
                        "!text-[#1EA0FF]": isDarkMode,
                      })}
                      style={{ color: theme.secondary ? theme.secondary : "" }}
                    >
                      View job on company site
                    </a>
                  )}
                  {companyData.linkedin_url && (
                    <a
                      href={companyData.linkedin_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx(
                        "text-[#6E49C2] font-semibold mt-2 w-fit",
                        {
                          "!text-[#1EA0FF]": isDarkMode,
                        }
                      )}
                      style={{ color: theme.secondary ? theme.secondary : "" }}
                    >
                      View company on LinkedIn
                    </a>
                  )}
                  {companyData.glassdoor_url?.length > 0 && (
                    <a
                      href={companyData.glassdoor_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={clsx(
                        "text-[#6E49C2] font-semibold mt-2 w-fit",
                        {
                          "!text-[#1EA0FF]": isDarkMode,
                        }
                      )}
                      style={{ color: theme.secondary ? theme.secondary : "" }}
                    >
                      View company on Glassdoor
                    </a>
                  )}
                </div>
              </div>
              <ActionButtons
                isLoading={isLoading}
                isDarkMode={isDarkMode}
                accepted={accepted}
                btnOneLabel="Tell me about salary"
                btnTwoLabel="This doesn't suit me"
                onAccept={() => handleResponse(true)}
                onReject={() => setAccepted(false)}
              />
            </ContentLayout>
            <Transition
              show={accepted === false}
              enter="transition-opacity duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {accepted === false && (
                <div className="absolute">
                  <Popup
                    onSubmit={() => handleResponse(false)}
                    onCancel={() => setAccepted(true)}
                  />
                </div>
              )}
            </Transition>
          </div>
        </MainLayout>
      </Transition>
    );
  }

  return (
    <MainLayout>
      <Head title="CompanyProfile" />
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full h-screen pb-40 flex justify-center items-center">
          <Spinner size="lg" />
        </div>
      </Transition>
    </MainLayout>
  );
};
