import { Logo } from "./Logo";

type HeroProps = {
  title?: string;
  subtitle?: string;
  salary?: string;
  description?: string;
  logo?: string;
  jobType?: string;
  showLogo?: boolean;
  icon?: React.ReactNode;
  step?: number;
};

export const Hero = ({
  title,
  subtitle,
  salary,
  description,
  showLogo,
  logo,
  icon,
  step,
}: HeroProps) => {
  return (
    <div
      className={`relative text-white w-full md:w-2/3 2xl:w-1/2 mx-auto px-8 py-8 pt-4 pb-6 flex flex-col items-center text-center ${
        !step && "py-8 md:py-10"
      }`}
    >
      {showLogo && <Logo logo={logo} name={title} />}
      {icon}
      {title && (
        <h1
          className={`text-3xl mt-2 mb-6 font-[tiemposheadline-bold] ${
            !step && "!mb-2"
          } ${icon && "mt-3"}`}
          aria-label="hero title"
        >
          {title}
        </h1>
      )}
      {subtitle && (
        <p className="font-light my-0" aria-label="hero subtitle">
          {subtitle}
        </p>
      )}
      {salary && (
        <p className="font-light text-lg mt-0" aria-label="hero salary">
          <span className="font-bold">{salary}</span>
        </p>
      )}
      {description && (
        <p className="font-light w-full md:w-2/3" aria-label="hero text">
          {description}
        </p>
      )}
    </div>
  );
};
