import {
  ChatIcon,
  CheckIcon,
  ThumbsUpIcon,
  ThumbsDownIcon,
} from "@/components/Icons";
import { ContentLayout } from "@/components/Layout/ContentLayout";
import { ErrorPage } from "@/components/Layout/Error";
import { Feedback } from "./types";
import { FeedbackForm } from "./components/FeedbackForm";
import { Head } from "@/components/Head";
import { Hero } from "@/components/Layout/Hero";
import { MainLayout } from "@/components/Layout/MainLayout";
import { Popup } from "@/components/Elements/Popup";
import { RadioButton } from "./components/RadioButton";
import { Spinner } from "@/components/Elements/Spinner";
import { Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { useTheme } from "@/contexts";
import { useDataFromApi } from "@/api/auth-query";
import { useParams, useNavigate } from "react-router";
import { useState, useEffect } from "react";
import { useSubmitFeedbackFinished } from "./api/feedbackFinished";
import { useSubmitFeedbackRebook } from "./api/feedbackRebook";
import { useSubmitFeedbackWithdrawn } from "./api/feedbackWithdrawn";
import clsx from "clsx";

export const FeedbackFinal = () => {
  const { isDarkMode } = useTheme();
  const { uuid } = useParams();
  const { application, vacancy } = useDataFromApi();
  const navigate = useNavigate();
  const {
    mutateAsync: mutateFinished,
    isLoading: isFinishedLoading,
    isSuccess: isFinishedSuccess,
  } = useSubmitFeedbackFinished({});
  const {
    mutateAsync: mutateRebook,
    isSuccess: isRebookSuccess,
    isLoading: isRebookLoading,
  } = useSubmitFeedbackRebook({});
  const { mutateAsync: mutateWithdrawn, isSuccess: isWithdrawnSuccess } =
    useSubmitFeedbackWithdrawn({});
  const [contacted, setContacted] = useState<boolean | null>(null);
  const [reschedule, setReschedule] = useState<boolean | null>(null);
  const [withdraw, setWithdraw] = useState<boolean | null>(null);
  const [openWarning, setOpenWarning] = useState(false);

  useEffect(() => {
    if (
      vacancy?.data?.vacancy?.public_status! === "closed" &&
      (application?.data?.status !== "status_feedback" ||
        application?.data?.status !== "status_conversation")
    ) {
      navigate(`/prospect/${uuid}/job-closed`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy]);

  useEffect(() => {
    isRebookSuccess && navigate(`/prospect/${uuid}/choose-journey`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRebookSuccess]);

  const updateFeedbackFinished = async (feedback: Feedback) => {
    if (uuid) {
      try {
        await mutateFinished({
          feedback,
          uuid,
        });
      } catch (error) {
        if (error instanceof Error) {
          console.error(error);
          toast.error(error.message);
        } else {
          console.error(error);
          toast.error("An unexpected error occurred");
        }
      }
    }
  };

  const updateFeedbackRebook = async (feedback: Feedback, uuid: string) => {
    if (uuid) {
      try {
        await mutateRebook({
          feedback,
          uuid,
        });
        if (isRebookSuccess) {
          navigate(`/prospect/${uuid}/choose-journey`);
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error(error);
          toast.error(error.message);
        } else {
          console.error(error);
          toast.error("An unexpected error occurred");
        }
      }
    }
  };

  const updateFeedbackWithdrawn = async (feedback: Feedback, uuid: string) => {
    if (uuid) {
      try {
        await mutateWithdrawn({
          feedback,
          uuid,
        });
      } catch (error) {
        if (error instanceof Error) {
          console.error(error);
          toast.error(error.message);
        } else {
          console.error(error);
          toast.error("An unexpected error occurred");
        }
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (contacted !== null) {
      setReschedule(null);
      setWithdraw(null);
    }
  }, [contacted]);

  useEffect(() => {
    if (reschedule === false) {
      setOpenWarning(true);
    }
  }, [reschedule]);

  if (application?.error?.message) {
    const errorMessage = application.error.response.data.error;
    const toastId = "error-" + errorMessage;
    if (!toast.isActive(toastId)) {
      toast.error(errorMessage, { toastId });
    }
    return <ErrorPage error={application?.error.message} />;
  }

  const onSubmit = (feedback: any) => {
    updateFeedbackFinished({ ...feedback, uuid });
  };

  const handleRebook = () => {
    updateFeedbackRebook({ conversation_contacted: false }, uuid!);
  };

  const handleWithdraw = async () => {
    await updateFeedbackWithdrawn({ conversation_contacted: false }, uuid!);
    setWithdraw(true);
    setOpenWarning(false);
  };

  const handleCancel = () => {
    setTimeout(() => {
      setReschedule(null);
      setOpenWarning(false);
    }, 0);
  };

  if (application && vacancy) {
    return (
      <Transition
        show
        appear
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="w-full h-full"
      >
        <MainLayout>
          <Head title="How we can help you" description="Cielo TaaS" />
          <div className="flex flex-col">
            <Hero title="Your feedback" icon={<ChatIcon />} />
            <ContentLayout>
              <div className="flex flex-col grow px-0 md:px-6 w-full">
                <div
                  className={clsx(
                    "flex flex-col gap-1 justify-center items-center w-full md:w-2/3 text-center mx-auto mt-4 mb-10 text-black",
                    { "text-white": isDarkMode }
                  )}
                >
                  {isFinishedSuccess ? (
                    <Transition
                      show
                      appear
                      enter="transition-all duration-500 transform"
                      enterFrom="opacity-0 translate-y-4"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition-all duration-500 transform"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-4"
                    >
                      {isFinishedSuccess && (
                        <div className="flex flex-col justify-center items-center mb-60 lg:w-2/3 mx-auto">
                          <CheckIcon
                            className={clsx(
                              "xs:w-20 xs:h-20 sm:w-32 sm:h-32 text-gray-900",
                              {
                                "text-white/80": isDarkMode,
                              }
                            )}
                          />
                          <p className="text-2xl">
                            Your feedback has been submitted!
                          </p>
                          <p className="leading-7">
                            We aim to create the best recruitment experience and
                            will take note of any valuable input you may have
                            while using our new platform. Thank you for taking
                            your time to fill out this brief questionnaire.
                          </p>
                        </div>
                      )}
                    </Transition>
                  ) : (
                    <>
                      <Transition
                        show={!withdraw}
                        enter="transition-all duration-400"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-all duration-400"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <>
                          <p className="mb-8 leading-7">
                            Our records show that your conversation with the
                            recruiter has now concluded. If this isn't accurate,
                            you can try rescheduling your conversation for
                            another time. Otherwise you can provide any feedback
                            you think may be useful.
                          </p>
                          <div>
                            <p className="text-2xl">
                              Did your recruiter call you?
                            </p>
                            <div className="flex gap-4 justify-center my-10">
                              <RadioButton
                                label={<ThumbsUpIcon />}
                                value="Yes"
                                checked={
                                  contacted === true
                                    ? true
                                    : contacted === false
                                    ? false
                                    : false
                                }
                                onClick={() => setContacted(true)}
                              />
                              <RadioButton
                                label={<ThumbsDownIcon />}
                                value="No"
                                checked={
                                  contacted === true
                                    ? false
                                    : contacted === false
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  setContacted(false);
                                }}
                              />
                            </div>
                          </div>

                          <Transition
                            show={contacted ? true : false}
                            enter="transition-all duration-500 transform"
                            enterFrom="opacity-0 translate-y-4"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition-all duration-0 transform"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-4"
                            className="w-full"
                          >
                            <FeedbackForm
                              contacted={contacted}
                              isLoading={isFinishedLoading}
                              onSubmit={onSubmit}
                            />
                          </Transition>

                          <Transition
                            show={contacted === false ? true : false}
                            enter="transition-all duration-500 transform"
                            enterFrom="opacity-0 translate-y-4"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition-all duration-0 transform"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-4"
                          >
                            <p className="text-2xl">
                              Would you like to reschedule your conversation for
                              another time?
                            </p>
                            <p
                              className={clsx("text-gray-600", {
                                "text-slate-400": isDarkMode,
                              })}
                            >
                              After clicking{" "}
                              <span
                                className="block md:inline-block"
                                style={{ display: "inline-block" }}
                              >
                                <ThumbsUpIcon />
                              </span>{" "}
                              you will be redirected to the application
                              selection area.
                            </p>
                            <div className="flex gap-4 justify-center my-10 pb-40">
                              <Transition
                                show={!withdraw}
                                enter="transition-all duration-400"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-all duration-400"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <RadioButton
                                  label={
                                    isRebookLoading ? (
                                      <Spinner size="sm" className="my-1" />
                                    ) : (
                                      <ThumbsUpIcon />
                                    )
                                  }
                                  value="Yes"
                                  checked={
                                    reschedule === true
                                      ? true
                                      : reschedule === false
                                      ? false
                                      : false
                                  }
                                  onClick={() => handleRebook()}
                                />
                              </Transition>
                              <RadioButton
                                label={<ThumbsDownIcon />}
                                value="No"
                                checked={withdraw === true}
                                onClick={() => setReschedule(false)}
                              />
                            </div>
                          </Transition>
                        </>
                      </Transition>
                    </>
                  )}
                </div>
                <Transition
                  show={isWithdrawnSuccess}
                  enter="transition-all duration-500 transform"
                  enterFrom="opacity-0 translate-y-4"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition-all duration-500 transform"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-4"
                >
                  <div className="w-full md:w-2/3 mx-auto">
                    <p className="leading-7 text-center">
                      Thank you, you have now been withdrawn from the
                      application and we will make sure not to contact you from
                      this point. Thanks for taking your time to answer our
                      questions.
                    </p>
                  </div>
                </Transition>
              </div>
            </ContentLayout>
          </div>
          <Transition
            show={openWarning}
            enter="transition-all duration-400"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-all duration-400"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute">
              <Popup
                onCancel={() => handleCancel()}
                onSubmit={() => handleWithdraw()}
              />
            </div>
          </Transition>
        </MainLayout>
      </Transition>
    );
  }

  return (
    <MainLayout>
      <Head title="Job Description" />
      <Transition
        show
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-screen h-screen pb-40 flex justify-center items-center">
          <Spinner size="lg" />
        </div>
      </Transition>
    </MainLayout>
  );
};
