export const register = async (swUrl) => {
  if ('serviceWorker' in navigator) {
    const date = new Date();
    const versionQueryParam = `?v=${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}`;

    try {
      const registration = await navigator.serviceWorker.register(swUrl + versionQueryParam);

      setInterval(async () => {
        await registration?.update();

        if (registration.waiting) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }

        registration.active.postMessage({ type: 'GET_VERSION' });
      }, 1000 * 60 * 5);

      registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
      registration.active?.postMessage({ type: 'GET_VERSION' });
    } catch (error) {
      console.log('Failed to register service worker:', error);
    }
  }
};
