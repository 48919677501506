import { useQuery } from '@tanstack/react-query'
import { ExtractFnReturnType, QueryConfig } from '@/api/react-query';
import toast from "react-hot-toast"
import apiUrl from '@/config'
import axios from 'axios'

export const getApplication = async ({ uuid }: { uuid?: string }): Promise<any | undefined> => {
    return await axios.get(`${apiUrl}/candidates/${uuid}/details`)
    .catch(error => {
        return { error }
    })
}

type QueryFnType = typeof getApplication

type UseApplicationOptions = {
    uuid?: string | undefined,
    config?: QueryConfig<QueryFnType>
}

export const useApplication = ({ uuid, config }: UseApplicationOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['application', uuid],
        queryFn: () => getApplication({ uuid }),
        onError: (error) => {
            console.log(error)
            if (error instanceof Error) {
                console.log(error)
                toast.error(error.message)
            } else {
                toast.error("An unexpected error has occured")
            }
            return error
        },
        enabled: !!uuid,
        ...config,
    })
}